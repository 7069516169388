import React from 'react';
//import classnames from 'classnames';

import './VerificationCode.scss';
import {simpleNotification} from "../../../utils/notifications";

class VerificationCode extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      inputs: Array(6).fill(''),
      //timeLeft:this.getTimeFromStorage(this.props.verificationType)||0,
      timeLeft:0,
      frozenTimeLeft:0,
      isVisible:true,
      lastProps:this.props,
      isSendVisible:false,
      hasSend:false,
      isTimeVisibile:false,
    };
    //this.inputsEnd = React.createRef();
    this.inputsEnd = Array(6).fill().map(() => React.createRef());
    this.timerId = null;
    this.frozenTimerId = null;
    this.isErrorVisibile=true;
    this.curVCode=null;
    this.isFrozenVisibile=true;
  }

  componentDidMount() {
    //const { verificationType } = this.props;
    //const { timeLeft } = this.state;
    //console.log(this.inputsEnd,this.props.verificationType,this.props.sendVerificationCode);
    const { token } = this.props.auth;
    if(this.props.sendVerificationCode) {
      this.props.sendVerificationCode(token,this.props.verificationType,true);
    }
    /*if(timeLeft>0){
      //获取本地倒计时
      this.startTimer();
      //this.props.onSendVCode();
    }else{
      const { token } = this.props.auth;
      if(this.props.sendVerificationCode) {
        this.props.sendVerificationCode(token,this.props.verificationType,true);
      }
      this.setState({ timeLeft: 60 }, () => {this.startTimer()});

    }*/
  }

  componentWillUnmount() {
    clearInterval(this.timerId);
  }

  componentDidUpdate(prevProps, prevState) {
    //console.log(this.props.auth.isSending,prevProps.auth.isSending);
    if (this.props.auth.isSending === false && prevProps.auth.isSending === true) {
      if (!this.props.auth.errors.hasError) {
        simpleNotification({
          level: "success",
          title: "Email sending success.",
          message: "Please check your email for more information!",
        });
        //console.log(this.props.auth.message);
        if(this.props.auth.message){
          let time = Number(this.props.auth.message);
          //let curTime = new Date().getTime();
          if(time){
            //let lTime = Math.ceil((time-curTime)/1000);
            //console.log(time,curTime,Math.ceil((time-curTime)/1000));
            //this.saveTimeToStorage(this.props.verificationType,lTime);
            this.setState({ timeLeft: time,hasSend:true,isTimeVisibile:true,isSendVisible:false }, () => {this.startTimer()});
          }
        }
      }
      if (this.props.auth.errors.hasError) {
        //console.log(this.props.auth.errors);
        if(this.props.auth.errors.code&&this.props.auth.errors.code==='E_VERFICATION_CODE_IS_FROZEN'){
          //this.isFrozenVisibile=true;
          if(this.props.auth.errors.message&&this.props.auth.errors.message){
            let time = Number(this.props.auth.errors.message);
            //let curTime = new Date().getTime();
            if(time){
              //let lTime = Math.ceil((time-curTime)/1000);
              //console.log(time,curTime,Math.ceil((time-curTime)/1000));
              //this.saveTimeToStorage(this.props.verificationType,lTime);
              this.setState({ timeLeft: time,hasSend:true,isTimeVisibile:false,isSendVisible:false }, () => {this.startTimer()});
            }
          }
          return;
        }
        else if(this.props.auth.errors.code&&this.props.auth.errors.code==='E_VERFICATION_CODE_CAN_NOT_GEN'){
          let time = Number(this.props.auth.errors.message);
          if(time){
            this.setState({ timeLeft: time,isTimeVisibile:true,hasSend:true,isSendVisible:false }, () => {this.startTimer()});
          }
          return;
        }
       else if(this.props.auth.errors.code&&this.props.auth.errors.code==='E_VERIFICATION_CODE_HAS_NOT_EXPIRED'){
          this.setState({ timeLeft: 0,hasSend:true,isSendVisible:true});
          return;
        }else{
          //this.setState({hasSend:true});
        }
        simpleNotification({
          level: "error",
          title: "Email sending failed",
          message: "Please confirm if the email you provided is correct!",
        });

      }

    }
    if (this.props.auth.isValidating === false && prevProps.auth.isValidating === true) {
      if (this.props.auth.errors.hasError) {
        if(this.props.auth.errors.code&&this.props.auth.errors.code==='E_VERFICATION_CODE_IS_FROZEN'){
          //this.isFrozenVisibile=true;
          if(this.props.auth.errors.message&&this.props.auth.errors.message){
            let time = Number(this.props.auth.errors.message);
           // let curTime = new Date().getTime();
            if(time){
             // let lTime = Math.ceil((time-curTime)/1000);
             // console.log(time,curTime,Math.ceil((time-curTime)/1000));
              //this.saveTimeToStorage(this.props.verificationType,lTime);
              this.setState({ timeLeft: time,hasSend:true,isTimeVisibile:false }, () => {this.startTimer()});
            }
          }
        }
        else if(this.props.auth.errors.code&&this.props.auth.errors.code==='E_VERFICATION_CODE_IS_INVALID'){
          //this.isFrozenVisibile=true;
          if(this.props.auth.errors.message&&this.props.auth.errors.message&&this.props.auth.errors.message.indexOf('frozen time:')>0){
            let tTime = this.props.auth.errors.message.substring(this.props.auth.errors.message.indexOf('frozen time:')+12,this.props.auth.errors.message.length);
            if(tTime){
              tTime=Number(tTime);
            }
            //let curTime = new Date().getTime();
            if(tTime){
             // let lTime = Math.ceil((tTime-curTime)/1000);
              //console.log(tTime,curTime,Math.ceil((tTime-curTime)/1000));
              //this.saveTimeToStorage(this.props.verificationType,lTime);
              this.setState({ timeLeft: tTime,hasSend:true}, () => {this.startTimer()});
            }
          }
        }else{
          //this.setState({hasSend:true});
        }
        //reset input
        const newInputs = this.state.inputs.slice();
        for(let i=0;i<newInputs.length;i++){
          newInputs[i] = '';
        }
        this.setState({
          inputs: newInputs,
        });
        this.inputsEnd[0].current.focus();
      }else{
        this.closeVerificationCode();
        //this.saveTimeToStorage(this.props.verificationType,0);
        this.props.submitNext(this.props.verificationType,this.curVCode);
      }
    }
  }

  static getDerivedStateFromProps(nextProps,preState) {
    if(!preState.lastProps||!preState.lastProps.auth||!nextProps.auth){
      return;
    }
    if (preState.lastProps.auth.isSending === true && nextProps.auth.isSending === false) {
      if (!nextProps.auth.errors.hasError) {
        simpleNotification({
          level: "success",
          title: "Email sending success.1",
          message: "Please check your email for more information!",
        });
      }
      if (nextProps.auth.errors.hasError) {
        simpleNotification({
          level: "error",
          title: "Email sending failed1",
          message: "Please confirm if the email you provided is correct!",
        });

      }

    }
  }

  getTimeFromStorage = (verificationType) => {
    const time = !verificationType?'':localStorage.getItem(verificationType);
    //console.log(verificationType,time);
    if(time){
      let pTime = parseInt(time, 10);
      if(pTime&&pTime<=0){
        pTime=0;
      }
      return pTime;
    }
    return null;
  };

  saveTimeToStorage = (verificationType,time) => {
    localStorage.setItem(verificationType, time);
  };

  startTimer=()=>{
    if(this.timerId){
      clearInterval(this.timerId);
    }
    this.timerId = setInterval(() => {
      const { timeLeft } = this.state;
      const newTimeLeft = timeLeft - 1;
      //console.log('newTimeLeft:',newTimeLeft);
      //this.saveTimeToStorage(this.props.verificationType,newTimeLeft); // 每次更新时间都保存到 localStorage
      this.setState({ timeLeft: newTimeLeft }, () => {
        if (this.state.timeLeft <= 0) {
          clearInterval(this.timerId);
          this.setState({isSendVisible:true,isTimeVisibile:false});
          //this.saveTimeToStorage(this.props.verificationType,60);
        }
      });
    }, 1000);
}

  startFrozenTimer=()=>{
    this.frozenTimerId = setInterval(() => {
      const { frozenTimeLeft } = this.state;
      const newTimeLeft = frozenTimeLeft - 1;
      //console.log('newTimeLeft:',newTimeLeft);
      //this.saveTimeToStorage(this.props.verificationType,newTimeLeft); // 每次更新时间都保存到 localStorage
      this.setState({ frozenTimeLeft: newTimeLeft }, () => {
        if (this.state.frozenTimeLeft <= 0) {
          clearInterval(this.frozenTimerId);
          //this.saveTimeToStorage(this.props.verificationType,60);
        }
      });
    }, 1000);
  }

  checkCode = (inputs) => {
    const newInputs = inputs.slice();
    const { token } = this.props.auth;
    //console.log(newInputs,this.props.validateVerificationCode);
    if(this.props.validateVerificationCode) {
      this.curVCode=newInputs;
      this.props.validateVerificationCode(token,newInputs,this.props.verificationType);
      this.isErrorVisibile=true;
    }
    /*this.setState({
      error: true,
    });

    setTimeout(() => {
      this.setState((prevState) => ({
        inputs: prevState.inputs.map(() => ''),
      }));
      this.inputsEnd[0].current.focus();
      this.setState({
        error: false,
      });
    }, 2000);*/
  };

  handleResendClick = (event) => {
    this.setState({ hasSend: false,isTimeVisibile:false,isSendVisible:false }, () => {
     // this.startTimer();
    //this.isTimeVisibile=false;
      const { token } = this.props.auth;
      if(this.props.sendVerificationCode) {
        this.props.sendVerificationCode(token,this.props.verificationType);
      }
      this.isErrorVisibile=true;
    });
    event.preventDefault();
  };

  handleInputChange = (index, event) => {
    const value = event.key;
    const { inputs } = this.state;
    const newInputs = inputs.slice();
    newInputs[index] = value;

    this.setState({
      inputs: newInputs,
    });
//console.log(index,value,this.inputsEnd[index].current);
    if (value && index < this.inputsEnd.length-1) {
      //this.inputsEnd[index + 1].current.focus();
    } else if (value === '' && index > 0) {
      //this.inputsEnd[index + 1].current[index - 1].focus();
    }

    if (newInputs.every((input) => input !== ''&&input !== undefined)) {
     //console.log('Verification code:', newInputs);
      this.checkCode(newInputs.join(''));
    }
  };

  handleKeyDown = (index, event) => {
    //console.log(index,event.key);
    if (event.key.length===1&&((event.key >= '0' && event.key <= '9')||(event.key >= 'a' && event.key <= 'z')||(event.key >= 'A' && event.key <= 'Z'))) {
      //console.log('1',event.key,event.keyCode);
      event.preventDefault();
      this.handleInputChange(index, event);
      if(index < this.inputsEnd.length-1){
        this.inputsEnd[index + 1].current.focus();
      }
    } else if (event.key === 'Backspace') {
      //console.log(event.key,event.code,index,this.state.inputs[index],this.state.inputs);
      if ((this.state.inputs[index] === ''||this.state.inputs[index] ===undefined) && index > 0) {
        this.handleInputChange(index - 1, { target: { value: '' } });
        this.inputsEnd[index - 1].current.focus();
      } else if (this.state.inputs[index] !== '') {
        this.handleInputChange(index, { target: { value: '' } });
      }
      this.isErrorVisibile=false;
    } else if (event.key === 'ArrowLeft') {
      if (index > 0) {
        this.inputsEnd[index - 1].current.focus();
      }
    } else if (event.key === 'ArrowRight') {
      if (index < this.inputsEnd.length-1) {
        this.inputsEnd[index + 1].current.focus();
      }
    }else{
      //console.log('--------cur--------',this.inputsEnd[index].current.value);
      //this.inputsEnd[index].current.value='';
    }
  };

  closeVerificationCode=()=>{
    this.setState({
      //isVisible: false,
    });
    if(this.props.close){
      this.props.close();
    }
  }

  getVerificationCode=()=>{
    return this.curVCode;
  }

  render() {
    const { inputs, timeLeft,isVisible,hasSend,isTimeVisibile,isSendVisible } = this.state;
    if(!isVisible){
      return null;
    }
    const {errors} = this.props.auth;
    let isExpired=false;
    let isInValid=false;
    let isFrozen=false;
    //let isAleadySend=false;
    //let isSendTime=false;
    if(errors&&errors.hasError){
      if(errors.code==='E_VERIFICATION_CODE_HAS_EXPIRED'){
        isExpired=true;
      }
      if(errors.code==='E_VERFICATION_CODE_IS_INVALID'){
        if(errors.message&&errors.message.indexOf('frozen')>0){
          isFrozen=true;
        }else{
          isInValid=true;
        }
      }
      if(errors.code==='E_VERFICATION_CODE_IS_FROZEN'){
        isFrozen=true;
      }
      if(errors.code==='E_OPERATION_FROZEN'){
        isFrozen=true;
      }
      if(errors.code==='E_VERIFICATION_CODE_HAS_NOT_EXPIRED'){
        //isAleadySend=true;
      }
      if(errors.code==='E_VERFICATION_CODE_CAN_NOT_GEN'){
        //isSendTime=true;
      }
    }
    //console.log(isValidating,errors);
    // const leftDisabled = pagination.pageNumber === '1';
    // const rightDisabled = pagination.pageNumber >= pagination.totalPages;

    // const disabledStyling = {
    //   cursor: 'not-allowed',
    //   opacity: '0.2',
    // };

    return (
        <div className="verification-container-root">
          <div className="verification-container-div">
              <div className="verification-container">
                <div className="close-button" onClick={this.closeVerificationCode.bind(this)}>×</div>
                <h2>Enter Verification Code</h2>
                <div className="code-container" id="codeContainer">
                  {inputs.map((input,index)=>(
                  <input key={index} type="text" maxLength="1" className="code-input" autoComplete="off" value={input}
                         onKeyDown={(e) => this.handleKeyDown(index, e)}
                         ref={(input) => {if (index >=0){ this.inputsEnd[index].current = input;}}}/>
                    ))}
                </div>
                {hasSend&&<p className="email-text">Verification code has been sent to your email</p>}
                {(isExpired&&this.isErrorVisibile)&&<p className="error-message" id="errorMessage1">The verification code has expired, please choose to resend</p>}
                {(isInValid&&this.isErrorVisibile)&&<p className="error-message" id="errorMessage2">Invalid verification code, please try again</p>}
                {(isFrozen&&timeLeft>0)&&<p className="timer" id="timer2">Too many input errors, please resend validation after <span>{(timeLeft>60)?Math.ceil(timeLeft/60):timeLeft}</span> {(timeLeft>60)?'minutes':'seconds'}</p>}
                <div className="timer-container">
                  {(timeLeft>0&&isTimeVisibile)&&<span className="timer" id="timer">Can resend in {timeLeft} seconds</span>}
                  {(timeLeft<=0&&isSendVisible)&&<button className="resend-button" id="resendButton" onClick={this.handleResendClick.bind(this)}>Resend verification code</button>}
                </div>
              </div>
          </div>
          <div className="verification-container-bg"></div>
        </div>
    );
  }
}

  VerificationCode.propTypes = {

};

export default VerificationCode;
