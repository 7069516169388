/* eslint-disable react/no-did-update-set-state */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import moment from 'moment';
import { CSVLink } from 'react-csv';
import Axios from "axios";
import './PaymentListIn.scss';

// Material UI
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

// Utils
import {
  stableSort,
  getSorting,
} from '../../../../utils/functions';

import Icon from '../../../../global/images/icons/Icon';

import {
  Typography
} from '@material-ui/core';

const TableHeaderCell = withStyles(theme => ({
  head: {
    color: '#9b9b9b',
    fontWeight: '400',
    letterSpacing: '1.2px',
    fontSize: '14px',
  },
}))(TableCell);

class PaymentListIn extends React.Component {
  constructor(props) {
    super(props);

    const today = new Date()
    const sevenDaysBefore = new Date()
    sevenDaysBefore.setDate(today.getDate() - 7)

    const formatDate = (date) => {
      if (!date) return ''
      const year = date.getFullYear()
      const month = String(date.getMonth() + 1).padStart(2, '0')
      const day = String(date.getDate()).padStart(2, '0')
      return `${year}-${month}-${day}`
    }

    this.props = props;
    this.state = {
      downloadModal: false,
      orderBy: false,
      order: 'desc',
      numPage: 1,
      filters: {
        searchAll: true,
        search: '',
        startDate: sevenDaysBefore.toISOString(),
        endDate: today.toISOString(),
      },
      search: "",
      dateStart: formatDate(sevenDaysBefore),
      dateEnd: formatDate(today),
      pagination: {
        totalUsers: 0,
        pageNumber: 1,
        totalPages: 1
      },
      sorting: {
        order: 'desc',
        orderBy: false,
      },
      records: [],
    };
  }
  
  async componentDidMount() {
    // Existing componentDidMount code
  }

  handleStartDateChange = (event) => {
    const { target: { value } } = event;
    if (!value) {
      this.setState({
        dateStart: ''
      })
      return
    }

    const recordsDate = new Date(value).toISOString()
    this.setState((prevState) => ({
      filters: {
        ...prevState.filters,
        startDate: recordsDate
      },
      dateStart: value,
      dateEnd: prevState.dateEnd && value > prevState.dateEnd ? '' : prevState.dateEnd,
    }))
  }

  handleEndDateChange = (event) => {
    const { target: { value } } = event;
    if (!value) {
      this.setState({
        dateEnd: ''
      })
      return
    }
    //change date to iso string
    const recordsDate = new Date(value).toISOString()
    this.setState((prevState) => ({
      filters: {
        ...prevState.filters,
        endDate: recordsDate
      },
      dateEnd: value
    }))
    this.refreshRecords()
  }

  //根据当前设置的搜索数据
  refreshRecords() {
    const { token } = this.props.auth;
    // 打印当前的搜索条件
    console.log('search filters data is:', this.state.filters)
    // 调用接口
    this.props.getRollInRecordAdmin(this.state.numPage, this.state.search ? this.state.search : ',', JSON.stringify(this.state.filters), false, token, 0);
  }

  UNSAFE_componentWillMount() {
    console.log('UNSAFE_componentWillMount called!');
    const { token } = this.props.auth;
    if (!this.props.admin.rollInAdmin.isLoading) {
      console.log('search filters data is:', this.state.filters)
      this.props.getRollInRecordAdmin(this.state.numPage, this.state.search ? this.state.search : ',', JSON.stringify(this.state.filters), false, token, 0);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    console.log('componentDidUpdate called!');
    const { token } = this.props.auth;
    if (this.state.sorting !== prevState.sorting) {
      this.setState({
        numPage: 1,
      });
      this.props.getRollInRecordAdmin(1, this.state.search ? this.state.search : ',', JSON.stringify(this.state.filters), false, token, 0);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    console.log('UNSAFE_componentWillReceiveProps called!');
    if (this.props.admin.rollInAdmin.isLoading === true && nextProps.admin.rollInAdmin.isLoading === false) {
      console.log('nextProps', nextProps);
      const { data: records_new, pagination } = nextProps.admin.rollInAdmin;
      if (pagination) {
        this.setState({
          pagination: pagination,
          records: records_new,
        })
      }
    }
  }

  paginationRight = () => {
    const { token } = this.props.auth;
    const { pagination } = this.props.admin.rollInAdmin;
    if (pagination.totalPages < this.state.numPage + 1) {
      return;
    }
    if (!this.props.admin.rollInAdmin.isLoading) {
      this.props.getRollInRecordAdmin(this.state.numPage + 1, this.state.search ? this.state.search : ',', JSON.stringify(this.state.filters), false, token, 0);
    }
    this.setState({
      numPage: this.state.numPage + 1,
    });
  }

  paginationLeft = () => {
    const { token } = this.props.auth;
    if (this.state.numPage === 1) {
      return;
    }
    if (!this.props.admin.rollInAdmin.isLoading) {
      this.props.getRollInRecordAdmin(this.state.numPage - 1, this.state.search ? this.state.search : ',', JSON.stringify(this.state.filters), false, token, 0);
    }
    this.setState({
      numPage: this.state.numPage - 1,
    });
  }

  // Determine sort direction for property; set state.order and state.orderBy
  handleRequestSort = (event, property) => {
    if (!property) {
      console.log('property is null');
      return;
    }
    const desc = this.state.order === 'desc' && this.state.orderBy === property;
    this.setState({
      order: desc ? 'asc' : 'desc',
      orderBy: property,
      sorting: {
        order: desc ? 'asc' : 'desc',
        orderBy: property,
      },
    });
  }

  // Sort & define payment rows; return elements to display
  getPaymentRows = (payments) => {
    if (payments.length) {
      const sortedPayments = stableSort(payments, getSorting(this.state.order, this.state.orderBy));

      const rows = sortedPayments.map((payment, index) => {
        const backgroundColor = index % 2 === 0 ? '#181717' : '#323f51'; // 偶数行淡一点，奇数行纯褐色
        return (
          <TableRow hover tabIndex={-1} key={payment.id||index} style={{ backgroundColor }}>
            <TableCell align="center">{payment.rollIn ? moment(payment.rollIn).format('MMMM DD, YYYY') : '-'}</TableCell>
            <TableCell align="center">{payment.userName ? payment.userName : '-'}</TableCell>
            <TableCell align="center">{payment.userEmail ? payment.userEmail : '-'}</TableCell>
            <TableCell align="center">{payment.questName ? payment.questName : '-'}</TableCell>
            <TableCell align="center">{payment.questID ? payment.questID : '-'}</TableCell>
            <TableCell align="center">{payment.questBalance > 0 ? `$${Math.abs(payment.questBalance).toFixed(2)}` : '0'}</TableCell>
            {/* <TableCell align="center">{payment.fee > 0 ? `$${Math.abs(payment.fee).toFixed(2)}` : '0'}</TableCell> */}
            {/* <TableCell align="center">{payment.balance > 0 ? `$${Math.abs(payment.balance).toFixed(2)}` : '0'}</TableCell> */}
            <TableCell align="center">{payment.accountBalance > 0 ? `$${Math.abs(payment.accountBalance).toFixed(2)}` : 0}</TableCell>
            <TableCell align="center">{payment.approveUserName ? payment.approveUserName : '-'}</TableCell>
          </TableRow>
        );
      });

      return rows;
    }
  }

  handleSearchChange(event) {
    const { target: { value } } = event;
    this.setState({
      search: value,
    });

    this.refreshRecords()
  }

  handleSearch = (event) => {
    const { search } = this.state
    console.log("method search called!,search string is:", search)

    this.refreshRecords()
  }

  // Define table headers; return array of headers
  getTableHeaders = () => {
    const tableHeaders = [
      {
        id: 'date', numeric: false, disablePadding: true, label: 'Date',
      },
      {
        id: 'userName', numeric: false, disablePadding: true, label: 'UserName',
      },
      {
        id: 'userEmail', numeric: false, disablePadding: true, label: 'User Email',
      },
      {
        id: 'questName', numeric: false, disablePadding: true, label: 'Quest',
      },
      {
        id: 'questId', numeric: true, disablePadding: true, label: 'Quest ID',
      },
      {
        id: 'earnings', numeric: true, disablePadding: true, label: 'Earnings',
      },
      {
        id: 'accountBalance', numeric: false, disablePadding: true, label: 'Account Balance',
      },
      {
        id: 'review', numeric: false, disablePadding: true, label: 'Review',
      }
    ];
    return tableHeaders;
  }

  // Define data for csv download; return object with headers and data
  getCSV = (payments) => {
    const data = [];

    for (let i = 0; i < payments.length; i += 1) {
      const payment = payments[i];

      // Standard CSV rows
      const dataRow = {
        date: payment.rollIn ? moment(payment.rollIn).format('MMMM DD, YYYY') : '-',
        userName: payment.userEmail,
        userEmail: payment.userName ? payment.userName : '-',
        questName: payment.userEmail ? payment.userEmail : '-',
        questId: payment.questID ? payment.questID : '-',
        earnings: payment.questBalance > 0 ? `$${Math.abs(payment.questBalance).toFixed(2)}` : 0,
        processingFee: payment.fee > 0 ? `$${Math.abs(payment.fee).toFixed(2)}` : 0,
        toPay: payment.balance > 0 ? `$${Math.abs(payment.balance).toFixed(2)}` : 0,
        accountBalance: payment.accountBalance > 0 ? `$${Math.abs(payment.accountBalance).toFixed(2)}` : 0,
        review: payment.approveUserName ? payment.approveUserName : '-',
      };

      data.push(dataRow);
    }

    // Standard CSV headers
    const headers = [
      { label: 'Transaction Date', key: 'date' },
      { label: 'Transaction Date', key: 'userName' },
      { label: 'User Email', key: 'userEmail' },
      { label: 'Quest Name', key: 'questName' },
      { label: 'Quest Id', key: 'questId' },
      { label: 'Earnings', key: 'earnings' },
      { label: 'Processing Fee', key: 'processingFee' },
      { label: 'Quest Payment Without fee', key: 'toPay' },
      { label: 'Total Balance', key: 'accountBalance' },
      { label: 'Review Admin', key: 'review' },
    ];

    return { headers, data };
  }

  // Call api to pull down paypal payments.

  openDownloadModal = () => {
    const { token, user } = this.props.auth;
    const isAdmin = user.role === 'admin';
    const isHelper = user.role === 'helper';

    if (isAdmin || isHelper) {
      this.props.getRollInCsv(0, this.state.search ? this.state.search : ',', JSON.stringify(this.state.filters), JSON.stringify(this.state.sorting), token, 0);
      this.setState({
        downloadModal: true,
      });
    }
  }

  closeDownloadModal = () => {
    this.setState({
      downloadModal: false,
    });
  }

  renderFiltersString = (forFileName) => {
    const { filters, search } = this.state;
    const filterArray = [];

    if (search !== "") {
      filterArray.push(`Order No: ${search}`);
    }

    if (filters.startDate && filters.endDate) {
      filterArray.push(`Transaction Date: ${moment(filters.startDate).format('MMMM DD, YYYY')} - ${moment(filters.endDate).format('MMMM DD, YYYY')}`);
    } else if (filters.startDate) {
      filterArray.push(`Transaction Date: After ${moment(filters.startDate).format('MMMM DD, YYYY')}`);
    } else if (filters.endDate) {
      filterArray.push(`Transaction Date: Before ${moment(filters.endDate).format('MMMM DD, YYYY')}`);
    }

    if (filterArray.length === 0) {
      return 'None';
    }

    // Determine delimiter
    if (forFileName) {
      const filterStrings = filterArray.map(filter => filter.replace(/[^a-z0-9+]+/gi, '-'));
      return filterStrings.join('_');
    }
    return filterArray.join(', ');
  }

  renderDownloadModal() {
    const { isLoading, data: payments } = this.props.admin.rollInsCsv;
    const csv = this.getCSV(payments);

    return (
      <section className="ModalMaster active">
        <section className="Modal active">
          <button onClick={this.closeDownloadModal.bind(this)} className="Modal__layer" aria-hidden />
          <section className="Modal__wrapper Modal__download">
            {
              (isLoading) &&
              <div className="Modal__download--spinner">
                <i className="fa fa-spinner fa-spin fa-3x fa-fw" />
              </div>
            }
            {
              (!isLoading) &&
              <div className="Modal__download--content">
                <div className="Modal__download--header">
                  Download
                </div>
                <div className="Modal__download--body">
                  <div className="Modal__download--row">
                    <CSVLink data={csv.data} headers={csv.headers} filename={`Records-Users-Roll-In-${this.renderFiltersString(true)}.csv`}>
                      <i className="fa fa-download" />&nbsp;{`Search Results (${payments.length})`}
                    </CSVLink>
                  </div>
                </div>
                <div className="Modal__download--footer">
                  <button
                    onClick={this.closeDownloadModal.bind(this)}
                    className="Modal__download--cta"
                  >
                    <i className="fa fa-times" />&nbsp;Close
                  </button>
                </div>
              </div>
            }
          </section>
        </section>
      </section>
    );
  }

  onDrop = (file) => {

    if (file?.target?.files[0]) {
      /* this.setState({
        uploading: req.id,
      }); */

      this.uploadFile(file?.target?.files[0]);
    }
  }

  uploadFile = (file) => {
    const { token } = this.props.auth;

    const formData = new FormData();
    formData.append("file", file);

    Axios({
      method: 'POST',
      url: '/api/v1/admin/user/update-tickets',
      headers: {
        Authorization: `JWT ${token}`,
        "Content-Type": "multipart/form-data",
      },
      data: formData,
    }).then(res => {

      alert('success');
    })
      .catch(err => {
        console.log(err, "err");
        alert('upload failed');
      });
  };

  /**
   * 
   * @param {
          {isAdmin && (<div><h2 className="APaymentList__title"> RECORDS:  <span>{pagination.totalUsers || 0} (Page {pagination.pageNumber || 0} of {pagination.totalPages || 0})</span></h2></div>)}} isAdmin 
   * @returns 
   */
  renderRecordsSelection() {
    const { pagination } = this.state;

    return (
      <div>
        {/* <div> */}
          <p className="APaymentList__title" style={{ fontSize: '30px', fontWeight: '400' }}>
            RECORDS: <span>{pagination.totalUsers} (Page {pagination.pageNumber} of {pagination.totalPages})</span>
          </p>
        {/* </div> */}
      </div>
    );
  }

  render() {
    const { isLoading, errors: paypalError } = this.props.admin.rollInAdmin;
    const { search, dateStart, dateEnd, pagination, records, numPage } = this.state;
    const { role } = this.props.auth.user;
    const isAdmin = role === 'admin';
    const isHelper = role === 'helper';

    console.log('render records data is:', records);

    const createSortHandler = property => (event) => {
      this.handleRequestSort(event, property);
    };

    return (
      <section className="APaymentList">
        <section className="APaymentList__wrapper">
          {isAdmin && this.renderRecordsSelection()}
          <section className="ToggleTable">
            <section className="ToggleTable__header">
              <div className="ToggleTable__header__left">
                <div className="Search_Input">
                  <Icon color='#bfbfbf' name='search' />
                  <input
                    type="text"
                    id="search-bar-input"
                    label="SearchBarInput"
                    placeholder="Please enter Username/Quset/Quset ID,Multiple lines can be separated by commas"
                    value={search}
                    onChange={e => this.handleSearchChange(e)}
                    onKeyDown={(e) => e.key === 'Enter' && this.handleSearch()}
                  />
                </div>

                <button style={{ marginLeft: '5px' }} onClick={this.refreshRecords.bind(this)}>
                  Search
                  {!isLoading ? (
                    <i style={{ marginLeft: '5px' }} className="fa fa-check green" />
                  ) : paypalError.hasError ? (
                    <i style={{ marginLeft: '5px' }} className="fa fa-times red" />
                  ) : null}
                </button>
                <Typography className="date_input_head_style">Roll-in time</Typography>
                {/* <label> */}
                <input type='date' placeholder="Select Start Date" className='date_input_style' value={dateStart} onChange={this.handleStartDateChange} />
                {/* </label> */}

                <input type='date' placeholder="Select End Date" className='date_input_style' value={dateEnd} onChange={this.handleEndDateChange} min={dateStart} />
                {(isAdmin || isHelper) && (
                  <button
                    style={{ marginLeft: '10px' }}
                    onClick={this.openDownloadModal.bind(this)}>
                    <i className="fa fa-download" />
                  </button>

                )}
              </div>
              <div className="ToggleTable__header__right">
                {(numPage !== 1) ?
                  <button className="regular" onClick={this.paginationLeft.bind(this)}>
                    <i className="fa fa-arrow-left" />
                  </button>
                  : null}

                {numPage !== pagination.totalPages && pagination.totalPages > 0 ?
                  <button className="regular" onClick={this.paginationRight.bind(this)}>
                    <i className="fa fa-arrow-right" />
                  </button> : null}
              </div>
            </section>
            <section className="ToggleTable__table-wrapper">
              <section className="ToggleTable__table flex-table">
                {/* <div className="row">
                  <div
                    className="table-cell headline"
                  >
                    Filters: {this.renderFiltersString(false)}
                  </div>
                </div> */}
                <Table>
                  <TableHead>
                    <TableRow>
                      {this.getTableHeaders().map(headCell => (
                        <TableHeaderCell
                          style={{ width: 'auto', backgroundColor: '#3C7AEE', color: 'white', fontSize: '14px', fontWeight: 'bold',paddingTop:'10px',paddingBottom:'10px' }}  
                          key={headCell.id}
                          align="center"
                          padding={headCell.disablePadding ? 'none' : 'normal'}
                          sortDirection="asc"
                        >
                          <TableSortLabel
                            active={this.state.orderBy === headCell.id}
                            direction={this.state.order}
                            onClick={createSortHandler(headCell.id)}
                            IconComponent={ArrowDropDownIcon}
                            style={{ marginLeft: '30px' }}
                          >
                            {headCell.label}
                            {this.state.orderBy === headCell.id ? (
                              <span style={{ display: 'none' }}>
                                {this.state.order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                              </span>
                            ) : null}
                          </TableSortLabel>
                        </TableHeaderCell>))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {(!isLoading && records) &&
                      this.getPaymentRows(records)
                    }
                  </TableBody>
                </Table>
                <section>
                  <div className="NoPayments">
                    {isLoading &&
                      <div className="PaymentList__loading">
                        <i className="fa fa-spinner fa-spin fa-3x fa-fw" />
                      </div>
                    }
                    {(!isLoading && !records.length) &&
                      <span> No Records Found </span>
                    }
                  </div>
                </section>

              </section>
            </section>
            <section className="ToggleTable__footer">
              {(numPage !== 1) ?
                <section className="ToggleTable__footer__left">
                  <button onClick={this.paginationLeft.bind(this)}>
                    <i className="fa fa-caret-left" />
                  </button>
                </section>
                : null}

              {numPage !== pagination.totalPages && pagination.totalPages > 0 ?
                <section className="ToggleTable__footer__right">
                  <button onClick={this.paginationRight.bind(this)}>
                    <i className="fa fa-caret-right" />
                  </button></section> : null}
            </section>
          </section>
        </section>
        {(this.state.downloadModal) && this.renderDownloadModal()}
      </section>
    );
  }
}

PaymentListIn.propTypes = {

};

export default PaymentListIn;