import React from 'react';
import './Log.scss';
import classNames from "classnames";
import LogContent from './LogContent';

const titleTabKey = [
  { label: 'Quest Log', value: 0 },
  { label: 'Profile Log', value: 1 },
  { label: 'Wallet Log', value: 2 },
];

class Log extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      usersTab: 0,
      summary: [],
    };
  }

  handleTabChange = (event, newValue) => {
    this.setState({ activeTab: newValue });
  };

  UNSAFE_componentWillMount() {
    console.log('UNSAFE_componentWillMount called!');
    const { token } = this.props.auth;
    if (!this.props.admin.logSummary.isLoading) {
      console.log('search filters data is:', this.state.filters)
      this.props.getLogSummary(token);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    console.log('UNSAFE_componentWillReceiveProps called!');
    if (this.props.admin.logSummary.isLoading === true && nextProps.admin.logSummary.isLoading === false) {
      console.log('nextProps', nextProps.admin.logSummary);
      // const { data } = nextProps.admin.logSummary;
      this.setState({ summary: nextProps.admin.logSummary.data });
    }
  }

  render() {
    const { usersTab,summary } = this.state
    return (
        <section className='Log__wrapper'>
          <section className="Log__container">
            <div className="LogContainer">
              <div className="Global__subTabs" style={{ padding: '20px 30px 10px 30px', flex: 1}}>
                <div className={classNames('Global__subTab', { active: usersTab === 0 })} onClick={() => this.setState({ usersTab: 0 })} >
                  Quest ({summary.count_quest})
                </div>
                <div className={classNames('Global__subTab', { active: usersTab === 1 })} onClick={() => this.setState({ usersTab: 1 })} >
                  Profile ({summary.count_profile})
                </div>
                <div className={classNames('Global__subTab', { active: usersTab === 2 })} onClick={() => this.setState({ usersTab: 2 })} >
                  Wallet ({summary.count_wallet})
                </div>
              </div>
              <h2 className='Log__title'>{titleTabKey.find(s => s.value === usersTab).label}</h2>
              <LogContent {...this.props} tab={usersTab} summary={summary}/>
            </div>
          </section>
        </section>
    );
  }
}

export default Log;