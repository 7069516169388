import React from "react";
import { Link } from "react-router";
import {
  transform,
  isEqual,
  isObject,
  orderBy,
  // countBy,
} from 'lodash';
import moment from 'moment-timezone';

import languages from './languages';
import defaultAvatar from '../images/avatars/defaultSpaceman.png';
import achievementIcon from '../components/images/requirement-icons/achievement.svg';
import twitterIcon from '../components/images/requirement-icons/twitter.svg';
import vodIcon from '../components/images/requirement-icons/vod.svg';
import trackStreamIcon from '../components/images/requirement-icons/track-stream.svg';
import verificationIcon from '../components/images/requirement-icons/verification.svg';
import linkIcon from '../components/images/requirement-icons/link.svg';
import calendarIcon from '../components/images/requirement-icons/calendar.png';
import hashtagIcon from '../components/images/requirement-icons/hashtag.svg';
import buildStreamIcon from '../components/images/requirement-icons/build-stream.svg';
import { simpleNotification } from './notifications';

const paypalPercentage = 0.00;

export const setify = arr => {
  const set = new Set(arr);
  return Array.from(set);
};

// write a function to strip the headers from a presgined s3 url and compose them into a header object
export const getHeadersFromS3Url = (url) => {
  const headers = {};

  const urlParts = url.split('?');
  const queryString = urlParts[1];
  const queryStringParts = queryString.split('&');

  queryStringParts.forEach((part) => {
    const keyValue = part.split('=');
    headers[keyValue[0]] = keyValue[1];
  });

  return headers;
}


export const QUEST_GRACE_PERIOD_DAYS = 7;
export const SECONDS_PER_DAY = 86400;
const SECONDS_PER_HOUR = 3600;

export const isNull = (val) => val === null || val === undefined;

export const tooManyZerosAndFalsey = (arr = []) => {
  if (!arr.length) return false;
  const tooManyZeros = arr.filter(item => !item);
  const tmZerosLength = tooManyZeros.length;
  const tmZerosPercent = (tmZerosLength / arr.length) * 100;
  return tmZerosPercent > 50;
}

export const getUserAvatar = (user) => user?.avatar || user?.connectedAccounts?.twitch?.profile_image_url || user?.connectedAccounts?.twitter?.profile_image_url || defaultAvatar;

export const getDateFormattedString = (date, format = 'MMM DD, YYYY') => moment(date).format(format);

export const getDateString = (startDateTime, endDateTime) => {
  const startMoment = moment(startDateTime);
  const endMoment = moment(endDateTime);

  const sameDay = startMoment.date() === endMoment.date();
  const sameYear = startMoment.year() === endMoment.year();
  const sameMonth = startMoment.month() === endMoment.month();

  let dateString = "";

  if (sameYear) {
    if (sameDay) {
      dateString = startMoment.format("MMM D, YYYY");
    } else {
      dateString = `${startMoment.format("MMM D")} - `;
      if (sameMonth) dateString += endMoment.format("D, YYYY");
      else dateString += endMoment.format("MMM D, YYYY");
    }
  } else {
    dateString = `${startMoment.format("MMM D, YYYY")} - ${endMoment.format(
      "MMM D, YYYY"
    )}`;
  }

  return dateString;
};

export const timeAgoCalc = (date, secondDate = null, text = 'ago') => {
  const baseline = secondDate ? new Date(secondDate) : new Date();
  const difference = baseline.getTime() - new Date(date).getTime();

  const timeAgo = difference < 3600000 ? `${Math.floor(difference / 60000)} minutes` : difference < 86400000 ? `${Math.floor(difference / 3600000)} hours` : `${Math.floor(difference / 86400000)} days`;

  return timeAgo + ` ${text}`;
}

export const differenceObject = (object, base) => {
  function changes(object, base) {
    return transform(object, (result, value, key) => {
      if (!isEqual(value, base[key])) {
        result[key] =
          isObject(value) && isObject(base[key])
            ? changes(value, base[key])
            : value;
      }
    });
  }
  return changes(object, base);
};

export const validateTwitterHandle = (username) => username.trim()[0] === "@";

export const copyToClipboard = (e) => {
  e.target.focus();
  document.execCommand("copy");
  // This is just personal preference.
  // I prefer to not show the the whole text area selected.
};

export const rounding = (number, amount = 2) => parseFloat(number).toFixed(amount);
export const roundingMoney = (number, amount = 2) => `${parseFloat(number).toFixed(amount)}`;

export const getQuestDurationHours = (quest) => {
  const startSeconds = quest.startDateTime
    ? new Date(quest.startDateTime).getTime() / 1000
    : 0;
  const endSeconds = quest.endDateTime
    ? new Date(quest.endDateTime).getTime() / 1000
    : 0;

  if (startSeconds === 0 || endSeconds === 0) return 0;

  const timeDifferenceHours =
    Math.abs(endSeconds - startSeconds) / SECONDS_PER_HOUR;

  return timeDifferenceHours;
};

export const getReferralBonus = (userQuest, quest) => {
  if (!quest) {
    return 0;
  }
  const { numberOfReferrals, gamePrice } = userQuest;
  const {
    isReferral,
    referralBonus,
    salesForBonus,
    referralBonusType,
    bonusValue,
    referralBonusTimes,
  } = quest;
  let bonus = 0;
  if (isReferral === true && referralBonus === true) {
    if (numberOfReferrals > 0) {
      let bonusTimes = Math.floor(numberOfReferrals / salesForBonus);
      if (referralBonusTimes === "once") {
        if (bonusTimes > 0) {
          bonusTimes = 1;
        }
      }
      if (referralBonusType === "bonus-in-percentage") {
        bonus = bonusTimes * ((bonusValue / 100) * (gamePrice / 100));
      }
      if (referralBonusType === "bonus-in-amount") {
        bonus = bonusTimes * bonusValue;
      }
    }
  }
  return bonus;
};

export const removeCommission = value => value - (value * paypalPercentage);

export const removeCommissionRounding = value => rounding(value - (value * paypalPercentage));

export const textTruncate = (str, lengthParam = 100, endingParam = '...') => {
  if (str.length > lengthParam) {
    let subString = str.substring(0, lengthParam - endingParam.length);
    if (subString[subString.length - 1] === ' ') {
      subString = subString.substring(0, subString.length - 1);
    }

    return subString + endingParam;
  }

  return str;
};

export const wordTruncate = (str, lengthParam = 5, endingParam = '...') => {
  const stringArr = str.split(" ");

  if (stringArr.length > lengthParam) {
    return stringArr.slice(0, lengthParam).join(" ") + endingParam;
  }

  return str;
};

export const numberWithCommas = (x) => {
  const parts = x.toString().split(".");
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return parts.join(".");
};

export const getCorrectTwitchAverages = (
  user,
  noOverride = false,
  days = 30
) => {
  const today = moment();
  const twitch =
    user.connectedAccounts && user.connectedAccounts.twitch
      ? user.connectedAccounts.twitch
      : false;
  const dateConnected =
    twitch && twitch.dateConnected ? twitch.dateConnected : user.createdAt;

  const isFrozen = user.freezeTwitchViewers
    ? user.freezeTwitchViewers.isFrozen
    : null;
  const whoFroze = user.freezeTwitchViewers
    ? user.freezeTwitchViewers.whoFroze
    : null;
  const dateFrozen = user.freezeTwitchViewers
    ? user.freezeTwitchViewers.dateFrozen
    : null;

  const sullyAVG = twitch ? twitch.AVGViewers : 0;
  const override = user.twitchAVGOverride;
  const { blacklist } = user;
  let correctAverage = 0;

  // 60 Day CCV
  if (days === 60) {
    const sixtyDayMark = moment(dateConnected).add(61, "days");
    const useStats = moment(today).isAfter(sixtyDayMark);

    const stat60 = user.statistics ? user.statistics.AVG60 : 0;
    const twitch60 = twitch ? twitch.avg60 : 0;

    correctAverage =
      isFrozen && !noOverride
        ? override
        : stat60 > 0 || useStats
          ? stat60
          : twitch60 > 0
            ? twitch60
            : sullyAVG;

    // 30 Day CCV
  } else if (days === 30) {
    const thirtyDayMark = moment(dateConnected).add(31, "days");
    const useStats = moment(today).isAfter(thirtyDayMark);

    const stat30 = user.statistics ? user.statistics.AVG30 : 0;
    const twitch30 = twitch ? twitch.avg30 : 0;

    correctAverage =
      isFrozen && !noOverride
        ? override
        : stat30 > 0 || useStats
          ? stat30
          : twitch30 > 0
            ? twitch30
            : sullyAVG;
  }

  return {
    correctAverage,
    isFrozen,
    whoFroze,
    dateFrozen,
    blacklist,
  };
};

export const getTwitchAverageViewers = (user, checkFrozen = true) => {
  if (checkFrozen) {
    const isFrozen = user?.freezeTwitchViewers?.isFrozen;

    if (isFrozen) {
      return user?.twitchAVGOverride || 0;
    }
  }

  const statAvg30 = user?.statistics?.AVG30 || 0;
  const twitchAvg30 = user?.connectedAccounts?.twitch?.avg30 || 0;
  return statAvg30 > 0 ? statAvg30 : twitchAvg30;
};

export const formatMonth2 = (month) => {
  switch (month) {
    case "01":
      return "January";
    case "02":
      return "February";
    case "03":
      return "March";
    case "04":
      return "April";
    case "05":
      return "May";
    case "06":
      return "June";
    case "07":
      return "July";
    case "08":
      return "August";
    case "09":
      return "September";
    case "10":
      return "October";
    case "11":
      return "November";
    case "12":
      return "December";
    default:
      return "Month";
  }
};

export const formatMonth = (arg) => {
  switch (arg) {
    case "January":
      return "01";
    case "February":
      return "02";
    case "March":
      return "03";
    case "April":
      return "04";
    case "May":
      return "05";
    case "June":
      return "06";
    case "July":
      return "07";
    case "August":
      return "08";
    case "September":
      return "09";
    case "October":
      return "10";
    case "November":
      return "11";
    case "December":
      return "12";
    default:
      return "";
  }
};

export const formatImageForCSV = (image, fitting = 2) => {
  return `=IMAGE(""${image}"", ${fitting})`;
};

const prepQuestsForCSV = (item) => {
  const last3Quests = item.last3Quests ? item.last3Quests : [];

  const { correctAverage } = getCorrectTwitchAverages(item);

  const questCondense = last3Quests.reduce((acc, q, i) => {
    const { timePlaying, requiredStreamTimeMinutes, title, AVGViewers } = q;

    const passFail =
      timePlaying - 5 >= requiredStreamTimeMinutes ? "PASSED" : "FAILED";

    const roundedAverageViewers = Math.round(AVGViewers);
    let roundedCorrectAverage = Math.round(correctAverage);
    if (roundedCorrectAverage === 0) {
      roundedCorrectAverage = 1;
    }

    acc += `${title} - `;
    acc += `${passFail} - `;
    acc += `Time Played: ${Math.round(timePlaying)}/${Math.round(
      requiredStreamTimeMinutes
    )} - `;
    acc += `AVG Viewers: ${roundedAverageViewers} (${(roundedAverageViewers / roundedCorrectAverage) * 100
      }%) - `;

    return acc;
  }, "");

  return JSON.stringify(questCondense);
};

const prepGamesForCSV = (item) => {
  const mostPlayed5Games = item.mostPlayed5Games ? item.mostPlayed5Games : [];

  const questCondense = mostPlayed5Games.reduce((acc, q, i) => {
    const { gameString, timePlaying, averageViewers, peakViewers } = q;

    acc += `${gameString} - `;
    acc += `Time Played: ${timePlaying} - `;
    acc += `Average Viewers: ${averageViewers} - `;
    acc += `Peak Viewers: ${peakViewers} - `;

    return acc;
  }, "");

  return JSON.stringify(questCondense);
};

export const questPreapproval = (array, quest) => {
  const headersCSV = [
    { label: 'Email', key: 'email' },
    { label: 'Username', key: 'username' },
    { label: 'Twitch Username', key: 'twitchUsername' },
    { label: 'Twitch AVG Viewers', key: 'twitchAverageViewers' },
    { label: 'Payment Amount', key: 'paymentAmount' },
    { label: 'User Frozen?', key: 'freezeTwitchViewers' },
    { label: 'Frozen Average', key: 'twitchAVGOverride' },
    { label: 'Status', key: 'status' },
    { label: 'Apply Time', key: 'applyTime' },
    { label: 'Approval/Decline Time', key: 'approvalDeclineTime' },
    { label: 'Submission Messages', key: 'submissions' },
    { label: 'Language', key: 'language' },
    { label: 'Twitter Handle', key: 'twitter' },
    { label: 'Discord Handle', key: 'discord' },
    { label: 'Location', key: 'location' },
    { label: 'Notes', key: 'notes' },
    { label: 'Last Three Quests', key: 'last3Quests' },
    { label: 'Last Five Games Played', key: 'last5Games' },
    { label: 'Profile Image URL', key: 'profileImageURL' },
    { label: 'Profile Image', key: 'profileImageFunction' },
  ];

  // Check for user info step on page 1
  if (quest.stepsPage1) {
    const userInfoSteps = quest.stepsPage1.filter(
      (step) => step.type === "collect-user-information"
    );
    if (userInfoSteps) {
      headersCSV.push({ label: "User Info Question", key: "userInfoQuestion" });
      headersCSV.push({ label: "User Info Answer", key: "userInfoAnswer" });
    }
  }

  const dataCSV = [];
  const pendingCSV = [];
  const approvedCSV = [];
  const declinedCSV = [];
  const leftQuestCSV = [];

  for (let i = 0; i < array.length; i += 1) {
    const item = array[i];
    const isFrozen =
      item.freezeTwitchViewers && item.freezeTwitchViewers.isFrozen;
    const { twitchAVGOverride: override } = item;
    const avg30 = (item.avg30) ? Math.round(item.avg30) : 0;
    const twitchAvg30 = (item.twitchAvg30) ? Math.round(item.twitchAvg30) : 0;
    const displayAvg30 = (avg30 > 0) ? avg30 : twitchAvg30;
    const userInfo = item.userInformation ? item.userInformation.filter(info => info.type === 'collect-user-information')[0] : false;
    const profileImageURL = item?.twitchProfilePicture;

    const data = {
      email: item.email,
      username: item.username,
      twitchUsername: item.twitchUsername,
      twitchAverageViewers: isFrozen ? override : displayAvg30,
      paymentAmount: `$${item.paymentAmount ? item.paymentAmount.toFixed(2) : 0}`,
      status: (item.status && item.status === 'pending') ? 'applied' : item.status,
      applyTime: moment(item.dateJoined).format('MMMM DD, YYYY - hh:mm a'),
      approvalDeclineTime: (item.approvalDeclineTime) ? moment(item.approvalDeclineTime).format('MMMM DD, YYYY - hh:mm a') : '- - -',
      language: item.language ? languages(item.language) : 'unset',
      discord: item.discord ? item.discord : 'unset',
      twitter: item.twitter ? item.twitter : 'unset',
      location: item.country ? item.country : 'unset',
      last3Quests: item.last3Quests ? prepQuestsForCSV(item) : 'none',
      last5Games: item.last3Quests ? prepGamesForCSV(item) : 'none',
      userInfoQuestion: userInfo ? userInfo.title : 'n/a',
      userInfoAnswer: userInfo ? userInfo.information : 'n/a',
      profileImageURL,
      profileImageFunction: formatImageForCSV(profileImageURL),

    };

    dataCSV.push(data);

    switch (item.status) {
      case "pending":
        pendingCSV.push(data);
        break;
      case "approved":
        approvedCSV.push(data);
        break;
      case "declined":
        declinedCSV.push(data);
        break;
      case "leftQuest":
        leftQuestCSV.push(data);
        break;
      default:
        break;
    }
  }
  return {
    headersCSV,
    dataCSV,
    pendingCSV,
    approvedCSV,
    declinedCSV,
    leftQuestCSV,
  };
};

/* Utility functions for sorting in Material UI tables. */

// Compare a[orderBy] and b[orderBy]; return int result
export const desc = (a, b, property) => {
  if (b[property] < a[property]) {
    return -1;
  }
  if (b[property] > a[property]) {
    return 1;
  }
  return 0;
};

// Sort array using comparison function cmp; return sorted array
export const stableSort = (array, cmp) => {
  if (Array.isArray(array)) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = cmp(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }
};

// Determine sorting order for parameter orderBy; return comparison function
export const getSorting = (order, orderBy) =>
  order === "desc"
    ? (a, b) => desc(a, b, orderBy)
    : (a, b) => -desc(a, b, orderBy);

// Dynamic Sorting for Property and Direction
export const dynamicSort = (property, order) => {
  let sortOrder = 1;

  if (order === "desc") {
    sortOrder = -1;
  }

  return (a, b) => {
    if (a[property] < b[property]) {
      return -1 * sortOrder;
    } else if (a[property] > b[property]) {
      return 1 * sortOrder;
    }
    return 0 * sortOrder;
  };
};

/**/

// calculates member and viewer tier
export const calculatePaymentAmount = (
  user,
  viewers,
  viewerTiers,
  memberTiers
) => {
  const {
    connectedAccounts,
    twitchUsername: utu,
    twitchUsernameLower: utul,
    // username,
  } = user;
  const twitchUsername =
    utul ||
    (utu
      ? utu.toLowerCase()
      : connectedAccounts && connectedAccounts.twitch
        ? connectedAccounts.twitch.username.toLowerCase()
        : null);

  const whatMemberTier = () =>
    memberTiers
      ? memberTiers.find(
        (item) => item.identity.toLowerCase() === twitchUsername
      )
      : null;

  const whatViewerTier = () => {
    const theTiers = orderBy(viewerTiers, ["minimumAverageViewers"], ["desc"]);
    let payment = 0;
    let minViewers = "notier";
    for (let i = 0; i < theTiers.length; i += 1) {
      if (viewers >= theTiers[i].minimumAverageViewers) {
        payment = theTiers[i].paymentAmount;
        minViewers = theTiers[i].minimumAverageViewers;
        break;
      }
    }
    return { payment, minViewers };
  };

  const memberTier = whatMemberTier();
  const isMemberTier = !!memberTier;
  const viewerObject = whatViewerTier();
  const viewerTier = viewerObject.payment;
  const value = memberTier ? memberTier.paymentAmount : viewerTier;

  return {
    value,
    viewerTier,
    isMemberTier,
    memberTier,
    minViewers: viewerObject.minViewers,
  };
};

export const isStreamingQuest = quest => quest && quest.type && (quest.type === 'tiered' || quest.type === 'tiered-one-time' || quest.type === 'tiered-multi-days');

export const isQuestOver = (quest) => {
  const today = moment().tz("America/Los_Angeles");
  const endDatePassed = today.diff(moment(quest.endDateTime)) > 0;

  return endDatePassed;
};

export const isQuestInGracePeriod = (quest) => {
  const today = moment().tz("America/Los_Angeles");
  const hoursOffset = -today.utcOffset() / 60;
  const endDateTimePlusWeek = moment(quest.endDateTime)
    .tz("America/Los_Angeles")
    .add(hoursOffset, "hours")
    .add(QUEST_GRACE_PERIOD_DAYS, "days");
  const questInGracePeriod = endDateTimePlusWeek > today;

  return questInGracePeriod;
};

// Get # requirements user has completed where approvalRequired = true (meaning an admin approved them)
// Returns int
export const getNumApprovedRequirements = (quest, userQuest) => {
  const approvedReqs = getApprovedRequirements(quest, userQuest);
  return approvedReqs ? approvedReqs.length : 0;
};

// Get the approved requirements user has completed where approvalRequired = true (meaning an admin approved them)
// Returns array
export const getApprovedRequirements = (quest, userQuest) => {
  let sumApprovedReqs = [];
  const approvableReqs = getQuestRequirements(quest, true);

  if (approvableReqs.length > 0) {
    // Get requirements that require approval which the user has completed
    const approvableReqIds = approvableReqs.map((r) => r.id);
    let userQuestRequirements = userQuest.requirements
      ? userQuest.requirements
      : [];
    userQuestRequirements = userQuestRequirements.filter(
      (r) =>
        r.questReqId &&
        approvableReqIds.includes(r.questReqId) &&
        r.status === "completed"
    );
    sumApprovedReqs = userQuestRequirements;
  }

  return sumApprovedReqs;
};

// Get # requirements where status is "to-review" & where approvalRequired = true
// Returns int
export const getNumInReviewRequirements = (quest, userQuest) => {
  let sumPendingReqs = 0;
  const approvableReqs = getQuestRequirements(quest, true);

  if (approvableReqs.length > 0) {
    // Get requirements that require approval which the user has completed
    const approvableReqIds = approvableReqs.map((r) => r.id);
    let userQuestRequirements = userQuest.requirements
      ? userQuest.requirements
      : [];
    userQuestRequirements = userQuestRequirements.filter(
      (r) =>
        r.questReqId &&
        approvableReqIds.includes(r.questReqId) &&
        r.status === "to-review"
    );
    sumPendingReqs = userQuestRequirements.length;
  }

  return sumPendingReqs;
};

// Get completion status user in quest
// Returns object
export const getCompletionStatus = (quest, userQuest, timePlaying) => {
  // Check completion status
  const approvableReqs = getQuestRequirements(quest, true);
  const completedReqNum = getNumApprovedRequirements(quest, userQuest);
  const streamTimeCompleted = getStreamTimeCompleted(quest, timePlaying);

  let completed = false;
  const requirementsCompleted = completedReqNum >= approvableReqs.length;

  if (requirementsCompleted && ((isStreamingQuest(quest) && streamTimeCompleted) || !isStreamingQuest(quest))) {
    completed = true;
  }

  // Check if end date has passed
  const today = moment().tz("America/Los_Angeles");
  const hoursOffset = -today.utcOffset() / 60;
  const untilDateTime = moment(quest.endDateTime)
    .tz("America/Los_Angeles")
    .add(hoursOffset, "hours")
    .toDate();
  const endDatePassed = today.diff(moment(quest.endDateTime)) > 0;

  // Check if we are in 7 day quest completion grace period
  let questInGracePeriod = false;
  if (quest.endDateTime) {
    const endDateTimePlusWeek = moment(quest.endDateTime)
      .tz("America/Los_Angeles")
      .add(hoursOffset, "hours")
      .add(QUEST_GRACE_PERIOD_DAYS, "days");
    questInGracePeriod = endDateTimePlusWeek > today;
  }

  // Determine status in quest
  let status = "F";
  let statusString = "Not Completed";

  if (completed) {
    status = "C";
    statusString = "Completed";
  } else if (
    (streamTimeCompleted && !requirementsCompleted && questInGracePeriod) ||
    !endDatePassed
  ) {
    status = "P";
    statusString = "In Progress";
  }

  return {
    streamTimeCompleted, // whether stream time was completed
    untilDateTime, // end date, adjusted for PST
    status, // one letter status code
    statusString, // human-readable status string
  };
};

// Get user's status for quest approval/completion
// Returns string
export const getUserQuestStatus = (quest, userQuest, timePlaying) => {
  if (userQuest.approvalStatus === "declined") {
    return "Rejected";
  } else if (userQuest.approvalStatus === "pending") {
    return "Awaiting Approval";
  } else if (userQuest.approvalStatus === "leftQuest") {
    return "Left Quest";
  }

  const { statusString } = getCompletionStatus(quest, userQuest, timePlaying);
  return statusString;
};

// Get boolean for whether stream time minimum has been met
// Returns bool
export const getStreamTimeCompleted = (quest, timePlaying) =>
  quest.requiredStreamTimeMinutes
    ? timePlaying >= quest.requiredStreamTimeMinutes
    : true;

// Get quest requirements
// Optional: only get ones where approvalRequired = true
// Returns flat array
export const getQuestRequirements = (quest = {}, approvalRequired = false) => {
  let requirements = [];

  if (quest?.requirements) {
    if (quest.requirements.beforeStream) {
      requirements = [...quest.requirements.beforeStream];
    }

    if (quest.requirements.duringStream) {
      requirements = [...requirements, ...quest.requirements.duringStream];
    }

    if (quest.requirements.afterStream) {
      requirements = [...requirements, ...quest.requirements.afterStream];
    }
  }

  if (approvalRequired) {
    requirements = requirements
      ? requirements.filter((r) => r && r.approvalRequired === true)
      : [];
  }

  return requirements;
};

export const getQuestRequirementsForDisplay = (quest = {}, approvalRequired = true) => {
  let requirements = [];

  if (quest?.requirements) {
    if (quest.requirements.beforeStream) {
      requirements = [...quest.requirements.beforeStream];
    }

    if (quest.requirements.duringStream) {
      requirements = [...requirements, ...quest.requirements.duringStream];
    }

    if (quest.requirements.afterStream) {
      requirements = [...requirements, ...quest.requirements.afterStream];
    }
  }

  if (approvalRequired) {
    requirements = requirements
      ? requirements.filter((r) => r && (r.approvalRequired === true || r.type === 'submit-text-optional' || r.optional))
      : [];
  }

  return requirements;
};

export const numeralFilter = (str) => str.split(/ /)[0].replace(/[^\d]/g, "");

export const numeralFilterDecimals = (str) =>
  str.replace(/[^0-9.]|\.(?=.*\.)/g, "");

export const viewerTiersWithSpacesLeft = (
  quest,
  participants,
  notifier = false
) => {
  const viewerTiersSpotsOccupied = [];
  if (quest.viewerTiers && quest.viewerTiers.length) {
    for (let i = 0; i < quest.viewerTiers.length; i += 1) {
      const found = participants.filter(
        (o) =>
          o.tier === "viewer" &&
          o.paymentAmount === quest.viewerTiers[i].paymentAmount
      );
      const currentTier = quest.viewerTiers[i];
      const { minimumAverageViewers, paymentAmount } = currentTier;
      const total = quest.viewerTiers[i].spotsAvailable;
      const spotsLeft = total - found.length;
      viewerTiersSpotsOccupied.push({
        spotsAvailable: `${spotsLeft}/${total}`,
        minimumAverageViewers,
        paymentAmount,
        spotsInt: spotsLeft,
      });
    }
  }

  return viewerTiersSpotsOccupied.length
    ? viewerTiersSpotsOccupied
    : [
      {
        spotsAvailable: "no tiers",
        minimumAverageViewers: 0,
        paymentAmount: 0,
        spotsInt: 0,
      },
    ];
};

export const capitalizeFirstLetter = (s) =>
  s.charAt(0).toUpperCase() + s.slice(1);

// Return true if quest end date is after today, false otherwise
export const isQuestActive = (quest) => {
  const today = moment().tz("America/Los_Angeles");
  const hoursOffset = -today.utcOffset() / 60;

  return (
    moment(quest.endDateTime)
      .tz("America/Los_Angeles")
      .add(hoursOffset, "hours") >= today
  );
};

export const flattenObject = (ob) => {
  const toReturn = {};

  for (const i in ob) {
    if (!ob.hasOwnProperty(i)) continue;

    if (typeof ob[i] === "object") {
      const flatObject = flattenObject(ob[i]);
      for (const x in flatObject) {
        if (!flatObject.hasOwnProperty(x)) continue;

        toReturn[`${i}.${x}`] = flatObject[x];
      }
    } else {
      toReturn[i] = ob[i];
    }
  }
  return toReturn;
};

export const isValidAddress = (address) => {
  const re = /^((https?|ftp|smtp|http):\/\/)/;
  return re.test(address);
};

export const formatLink = (url) => {
  const newUrl = (!url.startsWith("http") ? "https://" : "") + url;
  return newUrl;
};

export const linkify = (url) => {
  const newUrl = (!url.startsWith("http") ? "https://" : "") + url;

  return (
    <Link href={newUrl} target="_blank" rel="noopener noreferrer">
      {newUrl}
    </Link>
  );
};

export const parseLinks = (str) => {
  const patt = new RegExp(/[!@#$%^&*(),.?":{}|<>]/g);
  const words = str.split(" ");

  return (
    <>
      {words.map((word) => {
        if (isValidAddress(word)) {
          let url = word;
          let lastspot = null;
          const lastspotCheck = word.charAt(word.length - 1);
          if (patt.test(lastspotCheck)) {
            lastspot = lastspotCheck;
            url = word.slice(0, word.length - 1);
          }
          return (
            <>
              {linkify(url)}
              {lastspot}{" "}
            </>
          );
        }
        return `${word} `;
      })}
    </>
  );
};

export const escapeSpecialCharacters = (string) =>
  string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");

export const friendlyURICharacters = (string) =>
  string.replace(/[^a-zA-Z0-9_-]/g, "");

export const replaceSpaces = (string, char = "-") => string.replace(/ /g, char);

export const getDisplayGameTitle = (game) =>
  `Stream in the ${game.nameInTwitch} game directory`;

export const getQuestHashtagsTitle = (quest) => {
  const { questTags } = quest;

  let tagsString = "[QUEST TAGS]";
  if (questTags) {
    tagsString = questTags.map((tag) => tag.label).join(", ");
  }
  return `Include ${tagsString} hashtag(s) to your stream`;
};

export const getTrackingHashtagsTitle = (quest) => {
  const { trackingTags } = quest;

  let tagsString = "[TRACKING TAGS]";
  if (trackingTags) {
    tagsString = trackingTags.map((tag) => tag.label).join(", ");
  }
  return `Include ${tagsString} in your stream title`;
};

export const getStreamTrackTitle = (requiredTimeMinutes) => {
  const hours = requiredTimeMinutes
    ? Math.floor(requiredTimeMinutes / 60).toFixed(0)
    : 0;
  const minutes = requiredTimeMinutes
    ? (requiredTimeMinutes % 60).toFixed(0)
    : 0;

  let timeString = `Stream for ${hours} hours`;
  if (minutes && minutes > 0) {
    timeString += `, ${minutes} minutes`;
  }

  return timeString;
};

// Used to prepopulate requirements with default text, icon, etc.
export const preloadRequirement = (type, subtype, quest, game, qObj = {}) => {
  const preload = { title: "", tooltip: "", description: "" };
  const ifStreamDatesString = (date1, date2) => {
    if (date1 && date2) {
      return `Stream dates: ${moment(date1).toString()} - ${moment(date2).toString()}. `
    }
    return "";
  }

  switch (type) {
    case "submit-text-optional":
      preload.label = "Verification";
      preload.tooltip = "This is an *Optional* requirement.";
      break;
    case "submit-text":
      preload.label = "Verification";
      if (subtype === "schedule") {
        preload.label = "Schedule";
        preload.title = "Submit Your Schedule";
        preload.placeholder = "08/20/20XX at 12:00 PM PT";
        preload.description = `${ifStreamDatesString(qObj.startDateTime, qObj.endDateTime)}Let us know which date and time (including your timezone) you plan on doing your sponsored stream.`
      }
      break;
    case "submit-tweet":
      preload.label = "Verification";
      preload.tooltip =
        'Click <a href="https://help.twitter.com/en" target=”_blank” rel=”noopener noreferrer”>here</a> for instructions on how to tweet and other Twitter help topics';
      preload.placeholder = "Enter link to tweet";
      break;
    case "submit-link":
      preload.label = "Verification";
      if (subtype === "vod") {
        preload.tooltip =
          'Click <a href="https://help.twitch.tv/s/article/video-on-demand?language=en_US" target=”_blank” rel=”noopener noreferrer”>here</a> for instructions on how to share VODs';
        preload.placeholder = "Enter link to VOD";
      } else if (subtype === "clip") {
        preload.tooltip =
          'Click <a href="https://help.twitch.tv/s/article/how-to-use-clips?language=en_US" target=”_blank” rel=”noopener noreferrer”>here</a>  for instructions on how to create and share clips';
        preload.placeholder = "Enter link to clip";
      }
      break;
    case "distribute-key":
      break;
    case "distribute-link":
      preload.label = "Tracking link";
      if (subtype === "affiliate")
        preload.tooltip =
          'Click <a href="https://streamlabs.com/chatbot" target=”_blank” rel=”noopener noreferrer”>here</a> for instructions on how to setup a chatbot';
      else if (subtype === "campaign")
        preload.tooltip =
          'Click <a href="https://streamlabs.com/chatbot" target=”_blank” rel=”noopener noreferrer”>here</a> for instructions on how to setup a chatbot';
      break;
    case "display-stream-time":
      preload.title = getStreamTrackTitle(quest.requiredStreamTimeMinutes);
      break;
    case "display-hashtags":
      if (subtype === "tracking") {
        preload.title = getTrackingHashtagsTitle(quest);
        preload.description =
          "Note: we will be unable to track and verify that you've streamed without his hashtag ";
      } else if (subtype === "quest") {
        preload.title = getQuestHashtagsTitle(quest);
        preload.tooltip =
          'Click <a href="https://help.twitch.tv/s/article/guide-to-tags?language=en_US" target=”_blank” rel=”noopener noreferrer”>here</a> for a guide to hashtags on Twitch';
      }
      break;
    case "display-download":
      if (subtype === "overlay")
        preload.tooltip =
          'Click <a href="https://howto.streamlabs.com/streamlabs-obs-19/game-overlay-for-streamlabs-obs-3752" target=”_blank” rel=”noopener noreferrer”>here</a> for instructions on how to upload a overlay banner';
      else if (subtype === "banner")
        preload.tooltip =
          'Click <a href="https://help.twitch.tv/s/article/how-to-edit-info-panels?language=en_US" target=”_blank” rel=”noopener noreferrer”>here</a> for instructions on how to upload a panel banner';
      break;
    case "display-text":
      if (subtype === "build-stream")
        preload.tooltip =
          '"Build your stream" means streaming your usual content before introducing the sponsorship';
      else if (subtype === "game-name")
        preload.title = getDisplayGameTitle(game);
      break;
    default:
      break;
  }

  if (
    (type.includes("submit") && type !== 'submit-text-optional') ||
    (type === "display-text" && subtype === "checkbox")
  ) {
    preload.approvalRequired = true;
  } else {
    preload.approvalRequired = false;
  }

  preload.icon = getDefaultIcon(type, subtype, quest, game);

  return preload;
};

// Get default icon for requirement types
export const getDefaultIcon = (type, subtype, quest, game) => {
  switch (type) {
    case "submit-tweet":
      return twitterIcon;
    case "submit-text":
      if (subtype === "schedule") return calendarIcon;
      return verificationIcon;
    case "submit-link":
      if (subtype === "vod" || subtype === "clip") return vodIcon;
      return linkIcon;
    case "distribute-key":
      return (game?.logo || false);
    case "distribute-link":
      return linkIcon;
    case "display-stream-time":
      return trackStreamIcon;
    case "display-hashtags":
      return hashtagIcon;
    case "display-download":
      if (subtype === "overlay")
        return quest?.overlayImage || linkIcon;
      else if (subtype === "banner")
        return quest?.requiredBanner || linkIcon;
      break;
    case "display-text":
      if (subtype === "achievement") return achievementIcon;
      else if (subtype === "build-stream") return buildStreamIcon;
      else if (subtype === "game-name") return (game?.logo || false);
      else if (subtype === "checkbox") return verificationIcon;
      return verificationIcon;
    default:
      return verificationIcon;
  }

  return false;
};

export const keyDistributionStepExists = (quest) => {
  if (quest) {
    const requirements = getQuestRequirements(quest, false);
    let distribute = false;

    for (let i = 0; !distribute && i < requirements.length; i += 1) {
      const step = requirements[i];
      if (step.type === "distribute-key") distribute = true;
    }

    return distribute;
  }

  return false;
};

export const isProduction = () => window.location.hostname === "noiz.gg";

export const debounce = (func, delay = 0) => {
  let timeoutId;
  return (...args) => {
    const context = this;
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => func.apply(context, args), delay);
  };
};

export const throttle = (func, limit = 0) => {
  let inThrottle;
  return (...args) => {
    const context = this;
    if (!inThrottle) {
      func.apply(context, args);
      inThrottle = true;
      setTimeout(() => {
        inThrottle = false;
      }, limit);
    }
  };
};

export const isMongoId = (str) => {
  const checkForHexRegExp = new RegExp("^[0-9a-fA-F]{24}$");
  return checkForHexRegExp.test(str);
};

export const htmlToPlainText = (html) => {
  const divContainer = document.createElement("div");
  divContainer.innerHTML = html;
  return divContainer.textContent || divContainer.innerText || "";
};

export const onlyUniqueByKey = (theArr, key) => {
  const distinct = [];
  if (theArr && theArr.length) {
    for (let i = 0; i < theArr.length; i++) {
      const curr = theArr[i];
      const toSearch = curr[key];
      const theFoundIndex = distinct.findIndex(
        (item) => item[key] === toSearch
      );
      if (theFoundIndex === -1) distinct.push(curr);
    }
  }
  return distinct;
};

export const returnYoutubeId = (url) => {
  let ID = "";
  url = url
    .replace(/(>|<)/gi, "")
    .split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/);
  if (url[2] !== undefined) {
    ID = url[2].split(/[^0-9a-z_-]/i);
    ID = ID[0];
  } else {
    ID = url;
  }
  return ID;
};

export const getSubdomain = () =>
  window.location.host.split(":")[0].split(".").slice(0, -2).join(".");

export const getMyQuestsFiltered = (user) => {
  const { data: myQuests, isLoading: isLoadingMyQuests } = user.myQuests;
  const { data: myUserQuests, isLoading: isLoadingMyUserQuests } =
    user.myUserQuests;
  const { data: timedActivity } = user.timedActivity;
  const myQuestsFiltered = [];
  let requirementsOutstanding = 0;

  // Compare with userQuest.requirements and grab the corresponding status
  if (!isLoadingMyQuests && !isLoadingMyUserQuests) {
    for (let i = 0; i < myQuests.length; i += 1) {
      const quest = myQuests[i];
      const userQuest = myUserQuests.find((o) => o.quest === quest.id);

      // Only count requirements for active campaigns shown in quest book
      if (
        !quest.paymentMade &&
        !(
          userQuest &&
          (userQuest.status === "pending" ||
            userQuest.status === "declined" ||
            userQuest.status === "leftQuest")
        )
      ) {
        const userQuestTimedActivity = timedActivity.find((t) =>
          t.quest ? t.quest._id === quest.id : false
        );

        // Figure out how many submission requirements they completed
        let requirements = 0;
        if (quest.requirements) {
          requirements = getQuestRequirements(quest, true).length;
        }

        let completedRequirements = getNumApprovedRequirements(
          quest,
          userQuest
        );

        // Adjust for completed time requirement
        if (quest.requiredStreamTimeMinutes) {
          requirements += 1;

          if (userQuestTimedActivity) {
            const { timePlaying } = userQuestTimedActivity;
            if (timePlaying && timePlaying >= quest.requiredStreamTimeMinutes) {
              completedRequirements += 1;
            }
          }
        }

        if (completedRequirements < requirements) {
          requirementsOutstanding += requirements - completedRequirements;
        }
      }
    }
  }

  return { isLoadingMyQuests, myQuestsFiltered, requirementsOutstanding };
};

export const getPaymentAmount = (memberTiers, user, viewerTiers, userQuest = { averageViewersAtJoin: null }) => {
  const { averageViewersAtJoin } = userQuest;
  const averageViewersAtJoinIsNull = averageViewersAtJoin === null || averageViewersAtJoin === undefined;
  const memberT = memberTiers || [];
  const viewerT = viewerTiers || [];
  const username = user?.connectedAccounts?.twitch?.username || null;

  let paymentAmount = {
    paymentAmount: Number.MIN_SAFE_INTEGER,
    goal: "",
    goalEnabled: false,
  };
  let memberFound = false;

  if (memberT.length && username) {
    const foundMember = memberT.find(
      (mt) => mt.identity.toLowerCase() === username
    );

    if (foundMember) {
      memberFound = true;
      paymentAmount = {
        paymentAmount: foundMember.paymentAmount,
        goalEnabled: false,
        goal: "",
      };
    }
  }

  if (!memberFound && viewerT.length) {
    const averageViewers = averageViewersAtJoinIsNull ? getTwitchAverageViewers(user) || 0 : averageViewersAtJoin
    const reducedTiers = viewerT
      .sort(dynamicSort("minimumAverageViewers", "asc"))
      .reduce(
        (acc, vt) => {
          if (averageViewers >= vt.minimumAverageViewers)
            return {
              paymentAmount: vt.paymentAmount,
              goal: vt.goal,
              goalEnabled: vt.goalEnabled,
            };
          return acc;
        },
        { paymentAmount: Number.MIN_SAFE_INTEGER, goal: "", goalEnabled: false }
      );

    if (reducedTiers.paymentAmount !== Number.MIN_SAFE_INTEGER)
      paymentAmount = reducedTiers;
  }

  return paymentAmount;
};

export const keyValueSwap = (json) => {
  const ret = {};
  for (const key in json) {
    ret[json[key]] = key;
  }
  return ret;
};

// Only for non-negative integers; returns a string
export const pad = (n) => (n < 10 ? "0" + n : `${n}`);

export const immutablePop = (arr) => arr.slice(0, -1);

const leapYears = [
  "1908",
  "1912",
  "1916",
  "1920",
  "1924",
  "1928",
  "1932",
  "1936",
  "1940",
  "1944",
  "1948",
  "1952",
  "1956",
  "1960",
  "1964",
  "1968",
  "1972",
  "1976",
  "1980",
  "1984",
  "1988",
  "1992",
  "1996",
  "2004",
  "2008",
  "2012",
  "2016",
  "2020",
  "2024",
  "2028",
];

export const isLeapYear = (y) => leapYears.includes(y);

export const copyText = (event) => {
  const text = event.target.innerHTML;
  if(navigator&&navigator.clipboard) {
    navigator.clipboard.writeText(text);
    simpleNotification({
      level: "success",
      title: "Successfully Copied",
      message: `${text}`,
      autoDismiss: 2,
    });
  }
};

export const localizeMe = (d, s = "ddd MMM D, YYYY - hh:mm A z") => {
  const today = moment().tz("America/Los_Angeles");
  const hoursOffset = -today.utcOffset() / 60;
  return moment(d)
    .tz("America/Los_Angeles")
    .add(hoursOffset, "hours")
    .format(s);
};

export const validateEmail = (email) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  if (!re.test(String(email).toLowerCase())) {
    return false;
  }

  return true;
};

// write a function that turns arguments into query parameters
export const queryString = (obj, formEncoded = false) => {
  const entries = Object.entries(obj);
  const starter = formEncoded ? "" : "?";
  return entries.reduce((acc, [key, value], i) => {
    if (value) {
      acc += `${i !== 0 ? '&' : ''}${key}=${value}`;
    }
    return acc;

  }, starter);
};

export const formEncodedParams = (obj) => {
  const entries = Object.entries(obj);
  const newParams = new URLSearchParams();
  entries.forEach(([key, value]) => {
    console.log(key, value)
    newParams.append(key, value);
  });
  return newParams;
};


export const paymentVendorProcessingFee = (amount) => amount * paypalPercentage;

export const validateSignForm = (form, signIn = true) => {
  const {
    email,
    username,
    password,
    confirmPassword,
  } = form;

  const errors = [];

  if (signIn) {
    if (!identity) {
      errors.push('identity')
    }
  }



  const passwordRequirements = /^(?=.*[0-9])(?=.*[- ?!@#$%^&*\/\\])(?=.*[A-Z])(?=.*[a-z])[a-zA-Z0-9- ?!@#$%^&*\/\\]{8,30}$/;

  if (!password) {
    errors.push('password');
  }

  if (!signIn) {
    if (!email) errors.push('email');
    if (!username) errors.push('username');
    if (!validateEmail(email)) errors.push('email');
    if (!passwordRequirements.test(password)) {
      errors.push('password');
    }

    if (!confirmPassword || confirmPassword !== password) {
      errors.push('confirmPassword');
    }
  }

  return errors;
};

export const validateUrl = (value) => {
  return /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i.test(value);
};

export const extractInstantQuestPayment = quest => {
  const { viewerTiers = [] } = (quest || {});
  const vT = (viewerTiers || []).sort(dynamicSort('paymentAmount', 'desc'));
  return vT[0]?.paymentAmount || 0;
};

export const platformToIcon = (platform) => {
  switch (platform) {
    case 'Steam Store': {
      return 'steam';
    }
    default:
      return 'Windows PC';
  }
};

export const makeProper = (str = '') => {
  return str.slice(0, 1).toUpperCase() + str.slice(1);
};

export const nFormatter = (num, digits) => {
  const lookup = [
    { value: 1, symbol: "" },
    { value: 1e3, symbol: "K" },
    { value: 1e6, symbol: "M" },
    { value: 1e9, symbol: "G" },
    { value: 1e12, symbol: "T" },
    { value: 1e15, symbol: "P" },
    { value: 1e18, symbol: "E" }
  ];
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  var item = lookup.slice().reverse().find(function (item) {
    return num >= item.value;
  });
  return item ? (num / item.value).toFixed(digits).replace(rx, "$1") + item.symbol : "0";
}

export const humanFileSize = (bytes, si = false, dp = 1) => {
  //https://stackoverflow.com/questions/10420352/converting-file-size-in-bytes-to-human-readable-string
  const thresh = si ? 1000 : 1024;

  if (Math.abs(bytes) < thresh) {
    return bytes + ' B';
  }

  const units = si
    ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
    : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
  let u = -1;
  const r = 10 ** dp;

  do {
    bytes /= thresh;
    ++u;
  } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);

  return bytes.toFixed(dp) + ' ' + units[u];
};

export const activateIntercom = () => {
  window.Intercom("show", {
    app_id: "ito0ceux",
  });
};

export const navigationAssembly = (type, view) => {
  const isProduct = type === "Product";
  const isCampaign = type === "Campaign";
  if (isProduct) return { ...view, component: "default", tab: "home" };
  if (isCampaign) return { ...view, component: "default", tab: "quests" };
  return { ...view, component: `ViewAll${type}` };
};

export const getCurryFormattedString = (num) => {
  if(typeof num  === 'number'){
  }else{
    num=Number(num);
  }
  num=num.toFixed(2);
  return `${num}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};
