import React, { useState } from 'react';
import { PayPalButtons } from '@paypal/react-paypal-js';
import axios from 'axios';

const PaymentComponent = () => {
  const [paymentStatus, setPaymentStatus] = useState(null);

  const createOrder = async () => {
    try {
      const response = await axios.post(`http://localhost:1337/payment/create`);
      return response.data.orderID;
    } catch (error) {
      console.error("Error creating PayPal order:", error);
    }
  };

  const onApprove = async (data) => {
    try {
      const response = await axios.post(`http://localhost:1337/payment/capture`, {
        orderID: data.orderID,
      });
      setPaymentStatus(response.data.status);
    } catch (error) {
      console.error("Error capturing PayPal payment:", error);
      setPaymentStatus("failed");
    }
  };

  return (
    <div>
      <h2>Complete Your Payment</h2>
      <PayPalButtons
        createOrder={createOrder}
        onApprove={onApprove}
      />
      {paymentStatus && <p>Payment Status: {paymentStatus}</p>}
    </div>
  );
};

export default PaymentComponent;
