import React from "react";
import { useState, useEffect } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  styled,
  Tooltip,
  Typography,
} from "@material-ui/core";

//import { adminGetUserCounts } from "../../utils/apiv2";

import "./RollRecord.scss";
//import classNames from "classnames";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

const RollRecord = ({doSearch,startTime,endTime,type}) => {
  const [open, setOpen] = useState(false);
  const [startDate, setStartDate] = useState(startTime);
  const [endDate, setEndDate] = useState(endTime);
  //const [userCounts, setUserCounts] = useState(0);
  //const [loading, setLoading] = useState(true);
  //const [rollType,setRollType] = useState(0);

  useEffect(() => {
    let date = new Date();
    //setEndDate(endTime);
    date.setDate(date.getDate() - 7);
    //setStartDate(date);
    //setStartDate(startTime);

    //fetchUserCounts(date);
  }, []);

  const setDate = (type, date) => {
    console.log('setDate',typeof date);
    if (type === 'startDate') {
      setStartDate(date);
    } else {
      setEndDate(date);
    }
  };

  const handleClickOpen = type => {
    setOpen(type);
  };

  const handleSave = () => {
    setOpen(false);
    //fetchUserCounts();
    //console.log("date:" ,startDate,endDate);
    doSearch(startDate,endDate);
  };

  return (
    <div className='RollRecordCounts_MasterContainer'>
        <div className='RollRecordsCounts_WorkingContainer'>
          <div className='RollRecordCounts_Title'>
            {type==='rollIn'&&( <b>Roll-in time</b>)}
            {type==='rollOut'&&( <b>Roll-out time</b>)}
          </div>
          <Tooltip title="Start Date" placement="top">
            <Button variant="outlined" onClick={e => handleClickOpen('startDate')} style={{marginRight:10}}>
              {startDate.toISOString().split('T')[0]}
            </Button>
          </Tooltip>
          <Tooltip title="End Date">
            <Button variant="outlined" onClick={e => handleClickOpen('endDate')} style={{marginRight:10}}>
              {endDate.toISOString().split('T')[0]}
            </Button>
          </Tooltip>
          {/*<Tooltip title="Download PDF" placement="top">
            <button
                className="table-cell-cta"
                onClick={this.openPDFDownloadModal.bind(this, user)}
            >
              <i className="fa fa-download" />
            </button>
          </Tooltip>*/}
          <div className='NewUsersCounts_Count'>

          </div>
        </div>

      {/*<div className="ToggleTable__header__right">
        <div className={"regular_title"}>RECORD:1/1000</div>
        <button className="regular">
          <i className="fa fa-arrow-left" />
        </button>
        <button className="regular">
          <i className="fa fa-arrow-right" />
        </button>
      </div>*/}

      <BootstrapDialog
        onClose={handleSave}
        aria-labelledby="customized-dialog-title"
        open={!!open}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          Select {open === 'startDate' ? 'Start' : 'End'} Date
        </DialogTitle>
        <DialogContent dividers>
          <Typography gutterBottom>
            <input
              type="date"
              value={open === 'startDate' ? startDate.toISOString().split('T')[0] : endDate.toISOString().split('T')[0]}
              onChange={(e) => setDate(open, new Date(e.target.value))}
            />
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleSave}>
            Save changes
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
};

export default RollRecord;
