
import React from 'react';
import AdminTabs from '../util/tabs/AdminTabs';
import './PaymentContainer.scss';
import PaymentTab from './PaymentIndex';


export default function PaymentContainer(props) {

  return (
    <div className="admin-users-container">
      <AdminTabs {...props} />
      <PaymentTab {...props} />
    </div>
  );
}
