/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import { Link } from 'react-router';
import { orderBy } from 'lodash';
import moment from 'moment-timezone';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  styled,
  Tooltip,
  Typography
} from '@material-ui/core';
//import classNames from 'classnames';
import { simpleNotification } from '../../utils/notifications';
// import { Field, FieldArray, reduxForm } from 'redux-form';
// import CustomDropDownArray from '../../../../utils/redux-forms-custom-fields/CustomDropDownArray';
import './WithDraw.scss';
import paypal_logo from "../images/paypal-logo.png";
import {getCurryFormattedString, roundingMoney} from "../../utils/functions";
//import {red} from "@material-ui/core/colors";

const quickWidth = {};
// const quickWidth = {maxWidth: '10%'}

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
  '& .MuiDialog-paperWidthSm': {
    backgroundColor: '#204E78',
    borderRadius:'8px',
    display:'flex',
    alignItems:'center',
    width:'500px',
    padding:'10px'
  },
  '& .MuiDialogContent-dividers': {
    borderTop:'0px',
    borderBottom:'0px'
  },
  '& .MuiTypography-h6': {
    textTransform:'none'
  },
}));

class WithDraw extends React.Component {
  constructor(props) {
    super(props);
    this.idCopy = React.createRef();
    this.props = props;
    this.state = {
      copyModal: false,
      questCopyId: null,
      selectGame: null,
      tab: 1,
      curBalance:0,
      dialogConfirmOpen:false,
      dialogResultOpen:false,
    };
  }

  componentDidMount() {
    const { token } = this.props.auth;

    // Unify this four methods in one in the future
    this.props.getUserWallet(token);
  }

  copyText = (event) => {
    const text = event.target.innerHTML;
    if(navigator&&navigator.clipboard) {
      navigator.clipboard.writeText(text);
      simpleNotification({
        level: 'success',
        title: 'Successfully Copied',
        message: `${text}`,
        autoDismiss: 2,
      });
    }
  }

  generateRowsHTML = (quests, gameId) => {
    const today = moment().tz('America/Los_Angeles');
    const hoursOffset = -today.utcOffset() / 60;

    if (quests.length >= 1) {
      return quests.map((quest, index) => (
          <div className="row" key={quest.id}>
            <div className="table-cell">{quest.title}</div>
            <div
                className="table-cell"
                ref={this.idCopy}
                style={{ cursor: 'pointer', ...quickWidth }}
                onClick={e => this.copyText(e)}
                aria-hidden
            >
              {quest.id}
            </div>
            <div className="table-cell">{quest.startDateTime ? moment(quest.startDateTime).tz('America/Los_Angeles').add(hoursOffset, 'hours').format('MM-DD-YY hh:mm A') : '-'}</div>
            <div className="table-cell">{quest.endDateTime ? moment(quest.endDateTime).tz('America/Los_Angeles').add(hoursOffset, 'hours').format('MM-DD-YY hh:mm A') : '-' }</div>
            <div className="table-cell">
              { (quest.slug)
                  &&
                  <Link
                      to={`/quests/${quest.slug}`}
                      activeClassName="active"
                      href={`/quests/${quest.slug}`}
                      className="remove-key"
                      target="_blank"
                  >
                    <i className="fa fa-external-link" />
                  </Link> }
              { !(quest.slug)
                  &&
                  <span className="not-available">
                  Not Available
                </span>
              }
            </div>
            <div className="table-cell">
              {quest.needsReview}
            </div>
            <div className="table-cell">
              <Tooltip title="Edit" placement="top">
                <Link
                    to={`/admin/command-center/${gameId}/quests/${quest.id}/edit`}
                    activeClassName="active"
                    href={`/admin/command-center/${gameId}/quests/${quest.id}/edit`}
                    className="remove-key"
                >
                  <i className="fa fa-pencil" />
                </Link>
              </Tooltip>
              { (quest.requirePreapproval) &&
                  <Tooltip title="Preapproval" placement="top">
                    <Link
                        to={`/admin/command-center/${gameId}/quests/${quest.id}/preapproval`}
                        activeClassName="active"
                        href={`/admin/command-center/${gameId}/quests/${quest.id}/preapproval`}
                        className="remove-key"
                    >
                      <i className="fa fa-check-square-o" />
                    </Link>
                  </Tooltip>
              }
              { (quest.type === 'paid-bounty' || quest.type === 'paid bounty') &&
                  <Tooltip title="Details" placement="top">
                    <Link
                        to={`/admin/command-center/${gameId}/quests/${quest.id}/activity`}
                        activeClassName="active"
                        href={`/admin/command-center/${gameId}/quests/${quest.id}/activity`}
                        className="remove-key"
                    >
                      <i className="fa fa-line-chart" />
                    </Link>
                  </Tooltip>
              }
              { (quest.type === 'referral') &&
                  <Tooltip title="Details" placement="top">
                    <Link
                        to={`/admin/command-center/${gameId}/quests/${quest.id}/sales`}
                        activeClassName="active"
                        href={`/admin/command-center/${gameId}/quests/${quest.id}/sales`}
                        className="remove-key"
                    >
                      <i className="fa fa-line-chart" />
                    </Link>
                  </Tooltip>
              }
              { (quest.type === 'timed') &&
                  <Tooltip title="Details" placement="top">
                    <Link
                        to={`/admin/command-center/${gameId}/quests/${quest.id}/timed`}
                        activeClassName="active"
                        href={`/admin/command-center/${gameId}/quests/${quest.id}/timed`}
                        className="remove-key"
                    >
                      <i className="fa fa-line-chart" />
                    </Link>
                  </Tooltip>
              }
              { (quest.type === 'general' || quest.type === 'default' || quest.type === 'basic') &&
                  <Tooltip title="Details" placement="top">
                    <Link
                        to={`/admin/command-center/${gameId}/quests/${quest.id}/${'general'}`}
                        activeClassName="active"
                        href={`/admin/command-center/${gameId}/quests/${quest.id}/${'general'}`}
                        className="remove-key"
                    >
                      <i className="fa fa-line-chart" />
                    </Link>
                  </Tooltip>
              }
              { (quest.type === 'affiliate') &&
                  <Tooltip title="Details" placement="top">
                    <Link
                        to={`/admin/command-center/${gameId}/quests/${quest.id}/affiliate`}
                        activeClassName="active"
                        href={`/admin/command-center/${gameId}/quests/${quest.id}/affiliate`}
                        className="remove-key"
                    >
                      <i className="fa fa-line-chart" />
                    </Link>
                  </Tooltip>
              }
              { ((quest.type === 'tiered' || quest.type === 'tiered-one-time') && quest.frequency === 'one-time') &&
                  <Tooltip title="Details" placement="top">
                    <Link
                        to={`/admin/command-center/${gameId}/quests/${quest.id}/${quest.type}`}
                        activeClassName="active"
                        href={`/admin/command-center/${gameId}/quests/${quest.id}/${quest.type}`}
                        className="remove-key"
                    >
                      <i className="fa fa-line-chart" />
                    </Link>
                  </Tooltip>
              }
              { ((quest.type === 'tiered' || quest.type === 'tiered-multi-days') && quest.frequency === 'daily') &&
                  <Tooltip title="Details" placement="top">
                    <Link
                        to={`/admin/command-center/${gameId}/quests/${quest.id}/tiered-multiple-days`}
                        activeClassName="active"
                        href={`/admin/command-center/${gameId}/quests/${quest.id}/tiered-multiple-days`}
                        className="remove-key"
                    >
                      <i className="fa fa-line-chart" />
                    </Link>
                  </Tooltip>
              }
              { moment(quest.startDateTime).diff(moment(Date.now())) > 0 &&
                  <Tooltip title="Delete" placement="top">
                    <button className="remove-key" onClick={() => { this.deleteQuest(quest, index); }}>
                      <i className="fa fa-trash" />
                    </button>
                  </Tooltip>
              }
              <Tooltip title="Duplicate" placement="top">
                <button className="remove-key" onClick={() => { this.setState({ copyModal: true, questCopyId: quest.id, selectGame: gameId }); }}>
                  <i className="fa fa-copy" />
                </button>
              </Tooltip>
            </div>
          </div>
      ));
    }
    return (<div className="NoQuests">No Quests for this game</div>);
  }

  deleteQuest(quest, index) {
    if (window.confirm('This will delete the selected Quest, proceed?')) {
      const { token } = this.props.auth;
      fetch(`/api/v1/admin/quest/${quest.id}`, {
        method: 'delete',
        headers: {
          Authorization: `JWT ${token}`,
          'Content-Type': 'application/json',
        },
      }).then((response) => {
        this.props.commandCenter.quests.data.splice(index, 1);
        this.setState({ quests: this.props.commandCenter.quests.data });
      });
    }
  }

  copyCCQuest() {
    if (window.confirm('Are you sure about copy this Quest?')) {
      const { token } = this.props.auth;
      this.props.copyCCQuest(this.state.questCopyId, this.state.selectGame, token);
      this.setState({ copyModal: false });
    }
  }

  closeCopyModal = () => {
    this.setState({
      copyModal: false,
    });
  }

  renderCopyModal() {
    const { data: games } = this.props.commandCenter.games;
    // const { gameId } = this.props.router.params;
    let configGames = games.map(game => ({
      value: game.id,
      label: game.name,
    }));
    configGames = orderBy(configGames, ['label'], ['asc']);
    const renderSelectOptions = option => (<option key={option.value} value={option.value}>{option.label}</option>);
    return (
        <section className="ModalMaster active">
          <section className="Modal active">
            <button onClick={this.closeCopyModal.bind(this)} className="Modal__layer" aria-hidden />
            <section className="Modal__wrapper Copy">
              <div className="Modal__copy__header">
                Select the game
              </div>
              <div className="Modal__copy__body">
                <select
                    onChange={event => this.setState({ selectGame: event.target.value })}
                    className=""
                    value={this.state.selectGame}
                >
                  { configGames.map(renderSelectOptions) }
                </select>
              </div>
              <div className="Modal__copy__footer">
                <button
                    onClick={this.closeCopyModal.bind(this)}
                >
                  <i className="fa fa-times" /> Cancel
                </button>
                <button
                    onClick={this.copyCCQuest.bind(this)}
                >
                  <i className="fa fa-check" /> Copy
                </button>
              </div>
            </section>
          </section>
        </section>
    );
  }

  curBalance(num){
    num=Number(num);
      if(isNaN(num)){
        this.setState({ curBalance:0 });
      }else{
        const { withdraw:balance } = this.props.user.wallet.data;
        if(num>balance){
          num=balance;
        }
        this.setState({ curBalance:num });
      }
  }

  withdrawAll = (event)=>{
    event.preventDefault();

    const { withdraw:balance } = this.props.user.wallet.data;
    console.log(balance);
    this.setState({ curBalance: balance});
  }

  currencyFormatter(value) {
    // 将数字转换为带逗号的货币格式
    value=value.toFixed(2);
    //return `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    return `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

  }

  handleConfirmClose = () => {
    this.setState({ dialogConfirmOpen: false});
  };

  handleConfirmOpen = () => {
    this.setState({ dialogConfirmOpen: true});
  };

  handleResultClose = () => {
    this.setState({ dialogResultOpen: false});
    this.props.getUserWallet();
  };

  handleResultOpen = () => {
    this.setState({ dialogResultOpen: true});
  };

  submitOrder=()=>{
    const { token } = this.props.auth;
    this.props.withdrawWallet(this.state.curBalance,token);
    this.handleConfirmClose();
    this.handleResultOpen();
  }

  render() {
    //const { gameId } = this.props.router.params;
    const {isLoading } = this.props.commandCenter.quests;
    const { tab,curBalance,dialogConfirmOpen,dialogResultOpen } = this.state;
    const { withdraw:balance } = this.props.user.wallet.data;
    const {user} = this.props.auth;
    const isBind = user.paymentEmail;
    const { code: errorCode} = this.props.user.withdraw.errors;
    const { withdraw:withdrawBalance } = this.props.user.withdraw.data;
    let isNeedFlushAmount=false;

    if (isLoading) {
      //return (<i className="fa fa-spinner fa-spin fa-3x fa-fw" />);
    }
    let hasError=false;
    let haRes=false;

    if((typeof errorCode !== 'undefined' && errorCode !== null && errorCode.trim().length !== 0)&&errorCode!=='CREATE_WALLET_ORDER'){
      hasError=true;
      haRes=true;
      //this.handleConfirmClose();
      //this.handleResultOpen();
    }
    if(withdrawBalance>0){
      haRes=true;
    }
    if(dialogResultOpen){
      isNeedFlushAmount=true;
    }
    if(isNeedFlushAmount){
      //this.props.getUserWallet();
      isNeedFlushAmount=false;

    }
    //console.log(withdrawBalance,errorCode,haRes);
    //const today = moment().tz('America/Los_Angeles');
    //const hoursOffset = -today.utcOffset() / 60;

    //let activeQuests = filter(quests, x => (moment(x.endDateTime).tz('America/Los_Angeles').add(hoursOffset, 'hours') > today));
    //let finishedQuests = filter(quests, x => (moment(x.endDateTime).tz('America/Los_Angeles').add(hoursOffset, 'hours') <= today));

    //activeQuests = orderBy(activeQuests, ['endDateTime'], ['desc']);
    //finishedQuests = orderBy(finishedQuests, ['endDateTime'], ['desc']);

    //let showQuests = [];

    if (tab === 1) {
      //showQuests = [...activeQuests];
    } else if (tab === 2) {
      //showQuests = [...finishedQuests];
    }

    return (
        <section className="CCQuests">


          <section className="ToggleTable">
            <section className="ToggleTable__table-wrapper">
              <section className="ToggleTable__table flex-table">
                <section className="withdraw_head">
                <div className="withdraw_title">Withdrawable balance</div>
                <div className="withdraw_ins">
                  <Link  to={``}
                         activeClassName="active"
                         href={`/withdraw-instructions`}
                         className="remove-key"
                         target="_blank">Withdrawal Instructions</Link>
                </div>
                </section>
                <div className="MyQuestPage__totalEarned--firstTop">
                  <i className="fa fa-usd" aria-hidden="true" style={{marginTop:23}}/>
                  <div className="balance_max">{getCurryFormattedString(balance ? roundingMoney(balance, 2) : '0.00')}</div>
                </div>

                {/*{this.generateRowsHTML(showQuests, gameId)}*/}
              </section>
            </section>
          </section>
          <div className="ToggleTable__table-last-withdraw">
            <div className="withdraw_title">Method of withdrawal</div>
            <div className="pay_div">
            <img className="responsive active" style={{maxWidth: '150px' }} src={paypal_logo} alt="paypal logo" />
            </div>
            {!isBind&&(<section className="withdraw_nobind">
               <div className="withdraw_tip">Please go to My Profile to bind your PayPal account</div>
              <Link to={`/my-account`}
                    activeClassName='active'><button className="nobind_btn">My Profile</button></Link>
            </section>)}
            {isBind&&( <section  className="withdraw_bind">
              <div className="withdraw_bind_title">PayPal Account</div>
              <div className="withdraw_bind_value">{user.paymentEmail}</div>
              <div style={{alignSelf:'self-end'}}><Link  to={`/my-account`} href={`/my-account`} className="withdraw_bind_tool">Modify Account</Link></div>
              <div className="withdraw_bind_title">Withdrawal Amount</div>
              <span className="withdraw_bind_input_wrap">
                <input className="withdraw_bind_input" onChange={event => this.curBalance(event.target.value )} value={(this.state.curBalance>0&&this.state.curBalance<=balance)?this.state.curBalance:''}/>
              </span>
              <div style={{alignSelf:'self-end'}}><Link  to={``} href={``} onClick={this.withdrawAll} className="withdraw_bind_tool">Withdraw All Cash</Link></div>
              <div className="withdraw_tip">{curBalance<=0&&(balance>0?'Please enter the withdrawal amount':'Insufficient account balance')}</div>
              {curBalance<=0&&(<div className="bind_btn"><button style={{backgroundColor: '#C1CDCD' }}>Withdraw</button></div>)}
              {curBalance>0&&(<div className="bind_btn"><button onClick={this.handleConfirmOpen.bind(this)}>Withdraw</button></div>)}
            </section>)}

            <BootstrapDialog
                aria-labelledby="customized-dialog-title"
                open={!!dialogConfirmOpen}
            >

              <DialogContent dividers>
                <div style={{display:'flex',justifyContent:'center'}}>
                <Typography variant='body1' component="div"  gutterBottom style={{minWidth:'150px',}}>Withdrawal Amount</Typography>
                <Typography variant='body1' component="div"  gutterBottom style={{minWidth:'200px',textAlign:'right'}}>${getCurryFormattedString(curBalance ? roundingMoney(curBalance, 2) : '0.00')}</Typography>
                </div>
                <div style={{display:'flex',justifyContent:'center'}}>
                  <Typography variant='body1' component="div"  gutterBottom style={{minWidth:'150px',}}>PayPal Account</Typography>
                  <Typography variant='body1' component="div"  gutterBottom style={{minWidth:'200px',textAlign:'right'}}>{user.paymentEmail}</Typography>
                </div>
              </DialogContent>
              <DialogActions>
                <Button autoFocus style={{fontSize:'16px',fontWeight:'800',backgroundColor:'#f77929',color:'#fff',borderRadius:'8px',padding:'10px 50px',boxShadow:'0px 4px 8px rgba(0,0,0,0.5)',textTransform:'none'}} onClick={this.handleConfirmClose.bind(this)}>
                  Cancel
                </Button>
                <Button style={{marginLeft:'20px',fontSize:'16px',fontWeight:'800',backgroundColor:'#76b452',color:'#fff',borderRadius:'8px',padding:'10px 50px',boxShadow:'0px 4px 8px rgba(0,0,0,0.5)',textTransform:'none'}} onClick={this.submitOrder.bind(this)}>
                  Confirm
                </Button>
              </DialogActions>
            </BootstrapDialog>

            {haRes&&(<BootstrapDialog
                aria-labelledby="customized-dialog-title"
                open={!!dialogResultOpen}
            >
              <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title" style={{color:hasError?'#ff0000':''}}>
                {hasError? 'Failure to withdraw' : 'Withdrawal initiated Successful'}
              </DialogTitle>
              <DialogContent dividers>
                <div style={{display:'flex',justifyContent:'center'}}>
                  <Typography variant='body1' component="div"  gutterBottom style={{minWidth:'150px',color:hasError?'#ff0000':''}}>
                    {hasError?'There is a change in the account data, please refresh the page': 'withdrawal progress can be viewed in the transaction history'}
                  </Typography>
                 </div>
              </DialogContent>
              <DialogActions>
                <Button style={{marginLeft:'20px',fontSize:'16px',fontWeight:'800',backgroundColor:'#76b452',color:'#fff',borderRadius:'8px',padding:'10px 50px',boxShadow:'0px 4px 8px rgba(0,0,0,0.5)',textTransform:'none'}} onClick={this.handleResultClose.bind(this)}>
                  Confirm
                </Button>
              </DialogActions>
            </BootstrapDialog>)}

          </div>
          { (this.state.copyModal) && this.renderCopyModal() }
        </section>
    );
  }
}

WithDraw.propTypes = {

};

export default WithDraw;
