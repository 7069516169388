/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import { orderBy } from 'lodash';
import moment from 'moment-timezone';
import { Tooltip } from '@material-ui/core';
import { simpleNotification } from '../../utils/notifications';
// import { Field, FieldArray, reduxForm } from 'redux-form';
// import CustomDropDownArray from '../../../../utils/redux-forms-custom-fields/CustomDropDownArray';
import { getCurryFormattedString } from "../../utils/functions";
import RollRecord from "./RollRecord";
import './TransactionRollInRecord.scss';
import {CSVLink} from "react-csv";

const quickWidth = {};
// const quickWidth = {maxWidth: '10%'}
let dateInInit = new Date();
dateInInit.setDate(dateInInit.getDate()-7);

class TransactionRollInRecord extends React.Component {
  constructor(props) {
    super(props);
    this.dateRef = React.createRef();
    this.props = props;
    this.state = {
      copyModal: false,
      questCopyId: null,
      selectGame: null,
      downloadModal: false,
      downloadRecords: false,
      displayModal: false,
      tab: 1,
      numPage: 1,
      totalPage:1,
      filters: {
        startDate:dateInInit,
        endDate:new Date(),
      },
      sorting: {

      }
    };
  }

  componentDidMount() {
    const { token } = this.props.auth;
    localStorage.setItem('questsPathname', this.props.router.location.pathname);
    if (!this.props.user.rollIns.isLoading) {
      this.props.getWalletRollInRecord(this.state.numPage, JSON.stringify(this.state.filters), JSON.stringify(this.state.sorting), token, 0);
    }
  }

  copyText = (event) => {
    const text = event.target.innerHTML;
    if(navigator&&navigator.clipboard) {
      navigator.clipboard.writeText(text);
      simpleNotification({
        level: 'success',
        title: 'Successfully Copied',
        message: `${text}`,
        autoDismiss: 2,
      });
    }
  }

  generateRowsHTML1 = (quests) => {
    //const today = moment().tz('America/Los_Angeles');
    //const hoursOffset = -today.utcOffset() / 60;

    if (quests.length >= 1) {
      return quests.map((quest, index) => (
        <div className="row" key={quest.id}>
          <div className="table-cell">{quest.rollIn? moment(quest.rollIn).format('MMMM DD, YYYY') : ''}</div>
          <div
            className="table-cell"
            ref={this.idCopy}
            style={{ cursor: 'pointer', ...quickWidth }}
            onClick={e => this.copyText(e)}
            aria-hidden
          >
            {quest.questName}
          </div>
          <div className="table-cell">{quest.balance ? '$'+getCurryFormattedString(quest.balance): '-' }</div>
          <div className="table-cell">{quest.fee ? '$'+getCurryFormattedString(quest.fee): '-' }</div>
          <div className="table-cell">
            {quest.accountBalance ? '$'+getCurryFormattedString(quest.accountBalance): '-' }
          </div>

        </div>
      ));
    }
    return (<div className="NoQuests">No Records</div>);
  }

  getStateStyle=(state)=>{
    if(state===1){
      return "white";
    }else if(state===2){
      return "red";
    }else if(state===0){
      return "green";
    }
    return "white";
  }

  getStateStatus=(state)=>{
    if(state===1){
      return "Success";
    }else if(state===2){
      return "Failure";
    }else if(state===0){
      return "Withdrawing";
    }
    return "Withdrawing";
  }

  openDownloadModal = () => {
    const { token } = this.props.auth;
    //const isAdmin = user.role === 'admin';
    //const isHelper = user.role === 'helper';

      this.props.getWalletRollInCsv(0, JSON.stringify(this.state.filters), JSON.stringify(this.state.sorting), token,0);
      this.setState({
        downloadModal: true,
      });

  }

  closeDownloadModal = () => {
    this.setState({
      //downloadRecords:false,
      downloadModal: false,
    });
  }


  renderDownloadModal() {
    const { isLoading, data: rollInAllRecords } = this.props.user.rollInsCsv;
    const csv = this.getCSV(rollInAllRecords);

    return (
        <section className="ModalMaster active">
          <section className="Modal active">
            <button onClick={this.closeDownloadModal.bind(this)} className="Modal__layer" aria-hidden />
            <section className="Modal__wrapper Modal__download">
              {
                  (isLoading) &&
                  <div className="Modal__download--spinner">
                    <i className="fa fa-spinner fa-spin fa-3x fa-fw" />
                  </div>
              }
              {
                  (!isLoading) &&
                  <div className="Modal__download--content">
                    <div className="Modal__download--header">
                      Download
                    </div>
                    <div className="Modal__download--body">
                      <div className="Modal__download--row">
                        <CSVLink data={csv.data} headers={csv.headers} filename={`RollIn-${this.renderFiltersString(true)}.csv`}>
                          <i className="fa fa-download" />&nbsp;{`Search Results (${rollInAllRecords.length})`}
                        </CSVLink>
                      </div>
                    </div>
                    <div className="Modal__download--footer">
                      <button
                          onClick={this.closeDownloadModal.bind(this)}
                          className="Modal__download--cta"
                      >
                        <i className="fa fa-times" />&nbsp;Close
                      </button>
                    </div>
                  </div>
              }
            </section>
          </section>
        </section>
    );
  }

  getCSV = (rollInRecords) => {
    const data = [];

    for (let i = 0; i < rollInRecords.length; i += 1) {
      const rollInRecord = rollInRecords[i];

      // Standard CSV rows
      const dataRow = {
        orderDate:rollInRecord.rollIn?moment(rollInRecord.rollIn).format('MMMM DD, YYYY') : '',
        questId: rollInRecord.questID,
        questName: rollInRecord.questName,
        total: typeof rollInRecord.balance === 'number'? '$'+getCurryFormattedString(rollInRecord.balance): '-',
        questTotal: typeof rollInRecord.questBalance === 'number'? '$'+getCurryFormattedString(rollInRecord.questBalance): '-',
        fee: typeof rollInRecord.fee === 'number'? '$'+getCurryFormattedString(rollInRecord.fee): '-',
        accountBalance: typeof rollInRecord.accountBalance === 'number'? '$'+getCurryFormattedString(rollInRecord.accountBalance): '-',
        //amount: payment.amount ? Math.abs(payment.amount).toFixed(2) : '-',
        //processingFee: payment.processingFee ? Math.abs(payment.processingFee).toFixed(2) : '-',
        // questTitle: payment.questTitle,
        // expectedPayoutDate: payment.expectedPayoutDate ? moment(payment.expectedPayoutDate).format('MMMM DD, YYYY') : '',
        // paymentStatus: payment.paymentStatus,
        //paypalStatus: payment.paypalStatus ? paypalStatusKey.find(s => s.value === payment.paypalStatus).label : '',
        //transactionDate: payment.transactionDate ? moment(payment.transactionDate).format('MMMM DD, YYYY') : '',
      };

      data.push(dataRow);
    }

    // Standard CSV headers
    const headers = [
      { label: 'Date', key: 'orderDate' },
      { label: 'QuestID', key: 'questId' },
      { label: 'QuestName', key: 'questName' },
      { label: 'Total', key: 'total' },
      { label: 'QuestTotal', key: 'questTotal' },
      { label: 'Processing Fee', key: 'fee' },
      { label: 'Account Balance', key: 'accountBalance' },
    ];

    return { headers, data };
  }

  renderFiltersString = (forFileName) => {
    const { filters } = this.state;
    //console.log(filters);
    const filterArray = [];

    if (filters.searchAll && filters.searchValue) {
      filterArray.push(`Subject: ${filters.searchValue}`);
      filterArray.push(`Noiz Email: ${filters.searchValue}`);
      filterArray.push(`Noiz Username: ${filters.searchValue}`);
    } else if (filters.searchsearchSubject) {
      filterArray.push(`Subject: ${filters.searchValue}`);
    } else if (filters.searchNoizEmail) {
      filterArray.push(`Noiz Email: ${filters.searchValue}`);
    } else if (filters.searchNoiz) {
      filterArray.push(`Noiz Username: ${filters.searchValue}`);
    }

    // if (filters.paymentStatusAll) {
    //   filterArray.push('Payout Status: All');
    // } else {
    //   let paymentFilters = [];
    //   if (filters.paymentStatusSent) {
    //     paymentFilters.push('Sent');
    //   }
    //   if (filters.paymentStatusFailed) {
    //     paymentFilters.push('Failed');
    //   }
    //   if (filters.paymentStatusNotSent) {
    //     paymentFilters.push('Not Sent');
    //   }
    //   if (paymentFilters.length) {
    //     filterArray.push('Payment Status: ' + paymentFilters.join(", "));
    //   }
    // }

    if (filters.paypalStatusAll) {
      filterArray.push('Paypal Status: All');
    } else {
      const paypalFilters = [];
      if (filters.paypalStatusPending) {
        paypalFilters.push('Pending');
      }
      if (filters.paypalStatusSuccess) {
        paypalFilters.push('Success');
      }
      if (filters.paypalStatusDenied) {
        paypalFilters.push('Denied');
      }
      if (filters.paypalStatusRefunded) {
        paypalFilters.push('Refunded');
      }
      if (filters.paypalStatusPartiallyRefunded) {
        paypalFilters.push('Partially Refunded');
      }

      if (paypalFilters.length) {
        filterArray.push(`Paypal Status: ${paypalFilters.join(', ')}`);
      }
    }

    // if (filters.quests) {
    //   filterArray.push('Quests: ' + filters.quests.map((quest) => quest.label).join(", "));
    // }

    if (filters.startDate && filters.endDate) {
      filterArray.push(`Order Date: ${moment(filters.paymentFromDate).format('MMMM DD, YYYY')} - ${moment(filters.paymentToDate).format('MMMM DD, YYYY')}`);
    } else if (filters.startDate) {
      filterArray.push(`Order Date: After ${moment(filters.paymentFromDate).format('MMMM DD, YYYY')}`);
    } else if (filters.endDate) {
      filterArray.push(`Order Date: Before ${moment(filters.paymentToDate).format('MMMM DD, YYYY')}`);
    }

    if (filterArray.length === 0) {
      return 'None';
    }

    // Determine delimiter
    if (forFileName) {
      const filterStrings = filterArray.map(filter => filter.replace(/[^a-z0-9+]+/gi, '-'));
      return filterStrings.join('_');
    }
    return filterArray.join(', ');
  }

  generateRowsHTML2 = (quests) => {
    //const today = moment().tz('America/Los_Angeles');
    //const hoursOffset = -today.utcOffset() / 60;

    if (quests.length >= 1) {
      return quests.map((quest, index) => (
          <div className="row" key={quest.id}>
            <div className="table-cell">{quest.orderTime? moment(quest.orderTime).format('MMMM DD, YYYY') : ''}</div>
            <div
                className="table-cell"
                ref={this.idCopy}
                style={{ cursor: 'pointer', ...quickWidth }}
                onClick={e => this.copyText(e)}
                aria-hidden
            >
              {quest.orderId}
            </div>
            <div className="table-cell">{quest.balance ? '$'+getCurryFormattedString(quest.balance): '-' }</div>
            <div className="table-cell">{quest.accountBalance ? '$'+getCurryFormattedString(quest.accountBalance): '-' }</div>

          </div>
      ));
    }
    return (<div className="NoQuests">No Quests for this game</div>);
  }

  paginationRight = () => {
    const { token } = this.props.auth;
    const { pagination } = this.props.user.rollIns;
    //const isAdmin = user.role === 'admin';
//console.log(pagination.totalPages,this.state.numPage);
    if (pagination.totalPages < this.state.numPage + 1) {
      return;
    }

    if (!this.props.user.rollIns.isLoading) {
      this.props.getWalletRollInRecord(this.state.numPage+1, JSON.stringify(this.state.filters), JSON.stringify(this.state.sorting), token, 0);
    }
    this.setState({
      numPage: this.state.numPage + 1,
    });
  }

  paginationLeft = () => {
    const { token } = this.props.auth;
    //const isAdmin = user.role === 'admin';

    if (this.state.numPage === 1) {
      return;
    }

    if (!this.props.user.rollIns.isLoading) {
      this.props.getWalletRollInRecord(this.state.numPage-1, JSON.stringify(this.state.filters), JSON.stringify(this.state.sorting), token, 0);
    }
    this.setState({
      numPage: this.state.numPage - 1,
    });
  }

  deleteQuest(quest, index) {
    if (window.confirm('This will delete the selected Quest, proceed?')) {
      const { token } = this.props.auth;
      fetch(`/api/v1/admin/quest/${quest.id}`, {
        method: 'delete',
        headers: {
          Authorization: `JWT ${token}`,
          'Content-Type': 'application/json',
        },
      }).then((response) => {
        this.props.commandCenter.quests.data.splice(index, 1);
        this.setState({ quests: this.props.commandCenter.quests.data });
      });
    }
  }

  copyCCQuest() {
    if (window.confirm('Are you sure about copy this Quest?')) {
      const { token } = this.props.auth;
      this.props.copyCCQuest(this.state.questCopyId, this.state.selectGame, token);
      this.setState({ copyModal: false });
    }
  }

  closeCopyModal = () => {
    this.setState({
      copyModal: false,
    });
  }

  addOrder=()=>{
    const { token } = this.props.auth;
    this.props.addRollInOrder(100.21,120.67,20.46,'5babc50d00e8ae09cf624bec',token);
  }

  renderCopyModal() {
    const { data: games } = this.props.commandCenter.games;
    // const { gameId } = this.props.router.params;
    let configGames = games.map(game => ({
      value: game.id,
      label: game.name,
    }));
    configGames = orderBy(configGames, ['label'], ['asc']);
    const renderSelectOptions = option => (<option key={option.value} value={option.value}>{option.label}</option>);
    return (
      <section className="ModalMaster active">
        <section className="Modal active">
          <button onClick={this.closeCopyModal.bind(this)} className="Modal__layer" aria-hidden />
          <section className="Modal__wrapper Copy">
            <div className="Modal__copy__header">
              Select the game
            </div>
            <div className="Modal__copy__body">
              <select
                onChange={event => this.setState({ selectGame: event.target.value })}
                className=""
                value={this.state.selectGame}
              >
                { configGames.map(renderSelectOptions) }
              </select>
            </div>
            <div className="Modal__copy__footer">
              <button
                onClick={this.closeCopyModal.bind(this)}
              >
                <i className="fa fa-times" /> Cancel
              </button>
              <button
                onClick={this.copyCCQuest.bind(this)}
              >
                <i className="fa fa-check" /> Copy
              </button>
            </div>
          </section>
        </section>
      </section>
    );
  }

  doSearch=(dataStart,dataEnd)=> {
    this.setState({filters:{startDate:dataStart,endDate:dataEnd}},()=>{
      const { token } = this.props.auth;
      localStorage.setItem('questsPathname', this.props.router.location.pathname);
      if (!this.props.user.rollIns.isLoading) {
      this.props.getWalletRollInRecord(this.state.numPage, JSON.stringify(this.state.filters), JSON.stringify(this.state.sorting), token, 0);
      }
    });
  }

  setRefDate(){

  }

  render() {
    //const { gameId } = this.props.router.params;
    //const { initialValues: activeUser } = this.props;
    //const { isLoading,pagination} = activeUser.rollIns;
    const {rollIns={}} = this.props.user||{};
    const { isLoading=false,pagination={totalUsers:0,pageNumber:0,totalPages:0},data:rollInRecords={}} = rollIns||{};
    //const { isLoading,pagination} = this.props.user.rollOuts;
    //const isLoading=false;
    //const pagination={totalUsers:1,pageNumber:1,totalPages:1};
    //const { tab } = this.state;
    const {startDate,endDate} = this.state.filters;

    if (isLoading) {
      return (<i className="fa fa-spinner fa-spin fa-3x fa-fw" />);
    }

    //const today = moment().tz('America/Los_Angeles');
    //const hoursOffset = -today.utcOffset() / 60;

    //let activeQuests = filter(quests, x => (moment(x.endDateTime).tz('America/Los_Angeles').add(hoursOffset, 'hours') > today));
    //let finishedQuests = filter(quests, x => (moment(x.endDateTime).tz('America/Los_Angeles').add(hoursOffset, 'hours') <= today));

    //activeQuests = orderBy(activeQuests, ['endDateTime'], ['desc']);
    //finishedQuests = orderBy(finishedQuests, ['endDateTime'], ['desc']);

    //let showQuests = [];

    return (
      <section className="CCRollIns">

        {/*<div className="Global__tabs">
          <div className={classNames('Global__tab', { active: tab === 1 })} onClick={() => this.setState({ tab: 1 })} aria-hidden>
            Roll-in record
          </div>
          <div className={classNames('Global__tab', { active: tab === 2 })} onClick={() => this.setState({ tab: 2 })} aria-hidden>
            Roll-out record
          </div>
        </div>*/}

        <div className="RollRecord_SearchContainer">
          <RollRecord doSearch={this.doSearch} startTime={startDate} endTime={endDate} type={'rollIn'}/>
          {pagination.totalPages>1&&(<Tooltip title="Download CSV" placement="top">
            <button
                className="table-cell-cta"
                onClick={this.openDownloadModal.bind(this)}
            >
              <i className="fa fa-download" />
            </button>
          </Tooltip>)}
          <div className="ToggleTable__header__right">
            <div className={"regular_title"}>RECORD TOTAL:{pagination?pagination.totalUsers:'0'}</div>
            {pagination.totalPages>1&&(<div className={"regular_title"}>RECORD:{pagination?pagination.pageNumber:'-'}/{pagination?pagination.totalPages:'-'}</div>)}
            {pagination.totalPages>1&&( <button className="regular"  onClick={this.paginationLeft.bind(this)}>
              <i className="fa fa-arrow-left" />
            </button>)}
            {pagination.totalPages>1&&(<button className="regular"  onClick={this.paginationRight.bind(this)}>
              <i className="fa fa-arrow-right" />
            </button>)}
            {/*<button className="regular"  onClick={this.addOrder.bind(this)}>
              add
            </button>*/}
          </div>
        </div>

        <section className="ToggleTable">
          <section className="ToggleTable__table-wrapper">
            <section className="ToggleTable__table flex-table">
              <div className="row">
                <div className="table-cell headline">Date</div>
                <div className="table-cell headline" style={quickWidth}>Quest</div>
                <div className="table-cell headline">Total</div>
                <div className="table-cell headline">Processing Fee</div>
                <div className="table-cell headline">Account balance</div>
              </div>
              {this.generateRowsHTML1(rollInRecords)}
              {pagination.totalPages>1&&(<section className="ToggleTable__footer">
                <section className="ToggleTable__footer__left">
                  <button onClick={this.paginationLeft.bind(this)}>
                    <i className="fa fa-caret-left" />
                  </button>
                </section>
                <section className="ToggleTable__footer__right">
                  <button onClick={this.paginationRight.bind(this)}>
                    <i className="fa fa-caret-right" />
                  </button>
                </section>
              </section>)}
            </section>
          </section>
        </section>
        {(this.state.downloadModal) && this.renderDownloadModal()}
      </section>
    );
  }
}

TransactionRollInRecord.propTypes = {

};

export default TransactionRollInRecord;
